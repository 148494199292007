import { Button, Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, Link, Tooltip, Typography, styled, useMediaQuery, useTheme } from '@mui/material'
import React, {  useEffect, useMemo, useState } from 'react'
import { AutocompletePrediction, DirectionsData } from '../common/types';
import AutocompletePlaceInput from './AutocompletePlaceInput';
import { Add, Clear, MyLocation } from '@mui/icons-material';
import { Stack } from '@mui/system';
import DirectionInstructions from './DirectionInstructions';
import { NavArrowIcon } from '../common/Icons';
import { useCurrentLocation } from '../hooks/useCurrentLocation';
import { getAutocompletPredictionForCoordinate, predictionFromCoordinate } from '../common/helpers';
import { useTranslation } from 'react-i18next';

export interface DirectionsPanelProps {
    directionsData: DirectionsData;
    setDirectionsData: (data: DirectionsData) => void;
    resetDirection: () => void;
    onPlanRouteClick: () => void;
    isRouteLoading: boolean;
    isDrsPage: boolean;
}

const Container = styled(Stack)(({theme}) => ({
  padding: theme.spacing(4, 1),
  height: "100%",
  boxSizing: "border-box",
  overflowY: "auto",
  paddingBottom: "0px"
}))

const WaypointBar = styled('div')(({theme}) => ({
  display: "flex",
  paddingLeft: theme.spacing(4),
  "&:hover": {
    cursor: "pointer"
  },
  alignItems: "center"
}))

export const StickyButtonContainer = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    position: "sticky",
    bottom: 0,
    left: 0,
  },
  background: theme.palette.background.paper,
  width: "100%",
  boxSizing: "border-box",
 }
));


const DirectionsPanel: React.FC<DirectionsPanelProps> = ({
  directionsData,
  resetDirection,
  setDirectionsData,
  onPlanRouteClick,
  isRouteLoading,
  isDrsPage
 }) => {
  const [wazeURI, setWazeURI] = useState<string | null>(null);
  const getLocation = useCurrentLocation();

  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if(directionsData.route && directionsData.origin === null && directionsData.destination === null){
      resetDirection();
      setWazeURI(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directionsData]);

  const addWaypoint = () => {
    setDirectionsData({ ...directionsData, waypoints: [...directionsData.waypoints, null]});
  }

  const updateWaypoint = (index: number, result: AutocompletePrediction | null) => {
    let waypoints = directionsData.waypoints;
    waypoints.splice(index, 1, result);
    setDirectionsData({ ...directionsData, waypoints });
  }

  const removeWaypoint = (index: number) => {
    let waypoints = directionsData.waypoints;
    waypoints.splice(index, 1);
    setDirectionsData({ ...directionsData, waypoints });
  }

  const getCurrentLocation = () => {
      getLocation(async (result) => {
        const prediction = await getAutocompletPredictionForCoordinate(result);
         setDirectionsData({ ...directionsData, origin: prediction ?? result });
      });
  }

  const googleMapsURI = useMemo(() => {
    let uri = "https://www.google.com/maps/dir/?api=1";
    if(!(directionsData.origin instanceof google.maps.LatLng))
      uri += `&origin=${encodeURIComponent(directionsData.origin?.description ?? "")}`;

    uri += `&destination=${encodeURIComponent(directionsData.destination?.description ?? "")}`;

    if(directionsData.waypoints.length > 0){
      uri += "&waypoints="
      directionsData.waypoints.forEach((value, index) => {
        if(value && index === 0){
          uri += encodeURIComponent(value?.description)
        } else if (value){
          uri += encodeURIComponent(`|${value?.description}`)
        }
      });
    }

    return uri;
  }, [directionsData])

  const appleMapsURI = useMemo(() => {
    let uri = "https://maps.apple.com/?";

    uri += `daddr=${encodeURIComponent(directionsData.destination?.description ?? "")}`;
    if(!(directionsData.origin instanceof google.maps.LatLng))
      uri += `&saddr=${encodeURIComponent(directionsData.origin?.description ?? "")}`;

    return uri;
  }, [directionsData]);

  useEffect(() => {
    if(directionsData.destination && directionsData.origin){
      let uri = "https://waze.com/ul?";

      const fetchGeoLocation = async () => {
        const coder = new google.maps.Geocoder();
        const destinationResult = await coder.geocode({ 
          address: directionsData.destination?.description ?? "",
         })
         if(!(directionsData.origin instanceof google.maps.LatLng)){
            const originResult = await coder.geocode({ 
              address: directionsData.origin?.description ?? "",
            })
            if(originResult && originResult.results[0]){
              const uriComp = `${originResult.results[0].geometry.location.lat()},${originResult.results[0].geometry.location.lng()}`
              uri += "from="+encodeURIComponent(uriComp);
            }
        } else {
          const uriComp = `${directionsData.origin.lat()},${directionsData.origin.lng()}`
            uri += "from="+encodeURIComponent(uriComp);
        }
        if(destinationResult && destinationResult.results[0]){
              const uriComp = `${destinationResult.results[0].geometry.location.lat()},${destinationResult.results[0].geometry.location.lng()}`
              uri += "&ll="+encodeURIComponent(uriComp);
            }

        uri += "&navigate=yes"
        setWazeURI(uri)
      }

      fetchGeoLocation();
    }
  }, [directionsData]);

  return (
    <Container direction="column" spacing={4}>
      <Stack direction="column" spacing={1}>
        <Typography variant="title">{t("DIRECTIONS_PANEL.PLAN_ROUTE")}</Typography>
        <AutocompletePlaceInput
          value={directionsData.origin instanceof google.maps.LatLng ? predictionFromCoordinate(directionsData.origin) : directionsData.origin}
          onSelectResult={(result: AutocompletePrediction | null) => setDirectionsData({ ...directionsData, origin: result})}
          inputProps={{ 
            placeholder: t("DIRECTIONS_PANEL.FROM"),
            InputProps: { endAdornment: <InputAdornment position='end'>
            <Tooltip title={t("DIRECTIONS_PANEL.MY_LOCATION")}>
              <IconButton onClick={(event) => { getCurrentLocation(); event.stopPropagation(); }}><MyLocation /></IconButton>
            </Tooltip>
          </InputAdornment> } }}
        />
      </Stack>
      {directionsData.waypoints.map((value, index) => {
          return <AutocompletePlaceInput 
                    key={`waypoint-${index}`}
                    value={value}
                    inputProps={{ 
                      placeholder: t("DIRECTIONS_PANEL.WAYPOINT_NUM", { replace: { num: index + 1 } }),
                      InputProps: { endAdornment: 
                        <InputAdornment position='end'>
                          <Tooltip title={t("DIRECTIONS_PANEL.CLEAR_WAYPOINT")}>
                            <IconButton onClick={(event) => { removeWaypoint(index); event.stopPropagation(); }}><Clear /></IconButton>
                          </Tooltip>
                        </InputAdornment>
                     }}}
                    onSelectResult={(result: AutocompletePrediction | null) => { updateWaypoint(index, result) }} 
                    />
      })}
        <WaypointBar onClick={() => addWaypoint()} >
          <Add color='primary' />
          <Typography variant="body2">{t("DIRECTIONS_PANEL.ADD_WAYPOINT")}</Typography>
        </WaypointBar>
        <AutocompletePlaceInput
            inputProps={{ 
                placeholder: t("DIRECTIONS_PANEL.TO"),
            }}
            value={directionsData.destination}
            onSelectResult={(result: AutocompletePrediction | null) => setDirectionsData({ ...directionsData, destination: result})} />
        <Stack direction="column">
          <Typography>{t("DIRECTIONS_PANEL.AVOID")}:</Typography>
          <Stack direction="row" justifyContent="space-evenly">
              <FormControlLabel
                disableTypography
                label={<Typography variant='subtitle1'>{t("DIRECTIONS_PANEL.AVOID_OPTIONS.FERRY")}</Typography>}
                control={<Checkbox checked={directionsData.routeAvoidOptions.ferries} 
                onChange={(event) => setDirectionsData({
                    ...directionsData,
                    routeAvoidOptions: {...directionsData.routeAvoidOptions, ferries: event.target.checked}
                  })} />
                }
              />
              <FormControlLabel
                disableTypography
                label={<Typography variant='subtitle1'>{t("DIRECTIONS_PANEL.AVOID_OPTIONS.HIGHWAY")}</Typography>}
                control={<Checkbox checked={directionsData.routeAvoidOptions.highways} 
                onChange={(event) => setDirectionsData({
                    ...directionsData,
                    routeAvoidOptions: {...directionsData.routeAvoidOptions, highways: event.target.checked}
                  })} />
                }
              />
              <FormControlLabel
                disableTypography
                label={<Typography variant='subtitle1'>{t("DIRECTIONS_PANEL.AVOID_OPTIONS.TOLLS")}</Typography>}
                control={<Checkbox checked={directionsData.routeAvoidOptions.tolls} 
                onChange={(event) => setDirectionsData({
                    ...directionsData,
                    routeAvoidOptions: {...directionsData.routeAvoidOptions, tolls: event.target.checked}
                  })} />
                }
              />
          </Stack>
          { !isMobile && <DirectionInstructions route={directionsData.route} />}
        </Stack>
        <StickyButtonContainer paddingY={1}>
          <Button variant='contained' color="primary" disabled={!(directionsData.origin && directionsData.destination) || isRouteLoading } onClick={() => onPlanRouteClick()}>
            <NavArrowIcon />{t("DIRECTIONS_PANEL.PLAN_ROUTE_BUTTON")}
          </Button>
          { !isDrsPage &&
            <Grid container spacing={1} marginTop={1}>
              <Grid item xs={6}>
                <Button fullWidth variant='outlined' component={Link} href={googleMapsURI} target='_blank' color="primary"  disabled={!(directionsData.origin && directionsData.destination)}>Google Maps</Button>
              </Grid>
              <Grid item xs={6}>
                <Button fullWidth variant='outlined' component={Link} href={wazeURI ?? ""} target='_blank'  color="primary" disabled={!(directionsData.origin && directionsData.destination && wazeURI)}>Waze</Button>
              </Grid>
              <Grid item xs={12}>
                <Button fullWidth variant='outlined' component={Link} href={appleMapsURI} target='_blank' color="primary"  disabled={!(directionsData.origin && directionsData.destination)}>Apple Maps</Button>
              </Grid>
            </Grid>
          }
        </StickyButtonContainer>
    </Container>
  )
}

export default DirectionsPanel