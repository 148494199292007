import { useCallback, useContext } from "react"
import { CurrentLocationContext } from "../context/CurrentLocationContext"
import { ErrorMessageContext } from "../context/ErrorMessageContext";
import { LatLng } from "../common/types";

export const useCurrentLocation = () => {
    const { setLocation } = useContext(CurrentLocationContext);
    const { setErrorMessage } = useContext(ErrorMessageContext);

    return useCallback((onPositionSetCallback?: (value: LatLng) => void) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (position: GeolocationPosition) => {
                const pos = {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                };
               const posLatLng = new google.maps.LatLng({ lat: pos.lat, lng: pos.lng })
               setLocation(posLatLng);
               if(onPositionSetCallback){
                    onPositionSetCallback(posLatLng)
               }
              },
              () => {
                setErrorMessage({ title: "Helymeghatározás sikertelen", message: "Nincs engedélyezve a helyadatok lekérdezése", severity: "warning"})
              }
            );
          } else {
            // Browser doesn't support Geolocation
            setErrorMessage({ title: "Helymeghatározás sikertelen", message:"A böngésző nem támogatja a helyadatok lekérdezését."})
          }
    }, [setLocation, setErrorMessage])
}