import React, { useEffect, useState } from 'react'
import { DrsPointListAndDetailsDto, MapService, WastePointListWithNamesDto } from '../api/app.generated'
import { Button, Divider, Grid, IconButton, Link, Stack, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { EmailIcon, GlobeIcon, NavArrowIcon } from '../common/Icons';
import { LatLng } from '../common/types';
import { useTranslation } from 'react-i18next';

export interface DetailPanelProps {
    wastePointData: WastePointListWithNamesDto;
    onBack: () => void;
    onRoutePlanClick: (coord: LatLng, address: string) => void;
    hideClose?: boolean;
    isDrsMap?: boolean;
}

const DetailPanel: React.FC<DetailPanelProps> = ({ wastePointData, onBack, onRoutePlanClick, hideClose = false, isDrsMap = false }) => {
    const { t } = useTranslation();
    const [drsDetails, setDrsDetails] = useState<DrsPointListAndDetailsDto | null>(null);

    useEffect(() => {
        const mapService  = new MapService();
        if(drsDetails === null || drsDetails.id !== wastePointData.id){
            const fetchDrsPoint = async () => {
                const result = await mapService.getDrsPointDetails(wastePointData.id);
                setDrsDetails(result);
            }
            fetchDrsPoint();
       }

    }, [wastePointData, drsDetails]);

    return (
        <Stack direction="column" rowGap={1} padding={2}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant='title'>{drsDetails?.name ?? ""}</Typography>
                {!hideClose && <IconButton onClick={() => onBack()}><Close /></IconButton> }
            </Stack>
            <Divider />
            <Grid container justifyContent="space-between">
                <Grid item><Typography variant='subtitle1'>{t("DETAILS_PANEL.ADDRESS")}:</Typography></Grid>
                <Grid item>
                    <Typography variant="body1">
                        {`${drsDetails?.zipCode} ${drsDetails?.city}, ${drsDetails?.address}`}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justifyContent="space-between">
               <Grid item><Typography variant='subtitle1'>{t("DETAILS_PANEL.TYPE")}:</Typography></Grid>
                <Grid item><Typography variant="body1">{"REpont" }</Typography></Grid>
            </Grid>
            <>
                <Grid container justifyContent="space-between">
                    <Grid item><Typography variant='subtitle1'>{t("DETAILS_PANEL.OPEN_TIME")}</Typography></Grid>
                </Grid>
                <Grid container justifyContent="space-between">
                    <Grid item><Typography variant='subtitle1' marginLeft="12px">{t("DETAILS_PANEL.OPEN_TIME_WEEKDAYS")}:</Typography></Grid>
                    <Grid item><Typography variant='body1'>{drsDetails?.openTimeWeekdays ?? ""}</Typography></Grid>
                </Grid>
                <Grid container justifyContent="space-between">
                    <Grid item><Typography variant='subtitle1' marginLeft="12px">{t("DETAILS_PANEL.OPEN_TIME_SATURDAYS")}:</Typography></Grid>
                    <Grid item><Typography variant='body1'>{drsDetails?.openTimeSaturday ?? ""}</Typography></Grid>
                </Grid>
                <Grid container justifyContent="space-between">
                    <Grid item><Typography variant='subtitle1' marginLeft="12px">{t("DETAILS_PANEL.OPEN_TIME_SUNDAYS")}:</Typography></Grid>
                    <Grid item><Typography variant='body1'>{drsDetails?.openTimeSunday ?? ""}</Typography></Grid>
                </Grid>
                <Grid container justifyContent="space-between">
                    <Grid item><Typography variant='subtitle1'>{t("DETAILS_PANEL.MACHINE_TYPE")}:</Typography></Grid>
                    <Grid item><Typography variant='body1'>
                        {drsDetails?.isAutomaticPoint && t("MACHINE_TYPE.Automated")}
                        {drsDetails?.isAutomaticPoint && drsDetails.isManualPoint && ", "}
                        {drsDetails?.isManualPoint && t("MACHINE_TYPE.Manual")}
                    </Typography></Grid>
                </Grid>
            </>
            <Grid container rowSpacing={1} columnSpacing={1}>
                <Grid item xs={isDrsMap ? 12 : 6} md={isDrsMap ? 12 :4}>
                    <Button
                        fullWidth
                        variant='contained'
                        startIcon={<NavArrowIcon />}
                        onClick={() => onRoutePlanClick(
                            new google.maps.LatLng(
                                { lat: wastePointData.coordinates!.latitude, lng: wastePointData.coordinates!.longitude }
                            ),       
                            `${drsDetails?.zipCode} ${drsDetails?.city}, ${drsDetails?.address}`)}>
                            {t("DETAILS_PANEL.PLAN_ROUTE")}
                    </Button>
                </Grid>
                { !isDrsMap && <>
                    <Grid item xs={6} md={4}>
                        <Button
                            fullWidth
                            variant='contained'
                            disabled={!drsDetails?.email}
                            startIcon={<EmailIcon />}
                            component={Link}
                            href={`mailto:\\${drsDetails?.email}`}
                            target="_blank">
                                {t("DETAILS_PANEL.EMAIL_BUTTON")}
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Button
                            fullWidth
                            variant='contained'
                            disabled={!drsDetails?.webSiteUrl}
                            startIcon={<GlobeIcon />}
                            component={Link}
                            href={`${drsDetails?.webSiteUrl}`}
                            target="_blank">
                                {t("DETAILS_PANEL.WEBSITE_BUTTON")}
                        </Button>
                    </Grid>
                </>}
            </Grid>
        </Stack>
    )
}

export default DetailPanel