import GoogleMap from 'google-maps-react-markers';
import React, { useContext, useEffect, useState } from 'react'
import {  MapBounds, MapOptions, MapsLibraries } from '../common/types';
import useSupercluster from 'use-supercluster';
import { mapStyleArray } from '../themes/mapStyle';
import { Coordinate, DrsPointListAndDetailsDto, WastePointListDto, WastePointListWithNamesDto, WasteTypeWithIconDto } from '../api/app.generated';
import WastePointMarker from './WastePointMarker';
import ClusterMarker from './ClusterMarker';
import { GoogleMapsApiServiceContext } from '../context/GoogleMapsApiServiceContext';
import { useCurrentLocation } from '../hooks/useCurrentLocation';
import { CurrentLocationContext } from '../context/CurrentLocationContext';
import SearchLocationMarker from './SearchLoactionMarker';
import { AUTOMATED_REPONT_CONSTANT, REPONT_CONSTANT } from '../common/constants';
import { useTheme } from '@mui/material';


const mapOptions: MapOptions = {
    styles: mapStyleArray,
    fullscreenControl: false,
    streetViewControl: false,
    clickableIcons: false,
    zoomControl: false
}

export interface MapProps {
    wastePointList: Array<WastePointListWithNamesDto | DrsPointListAndDetailsDto>;
    selectedWastePointId?: number;
    wasteTypeList?: WasteTypeWithIconDto[];
    onMarkerClick?: (itemId: number, isDrs: boolean, event: React.MouseEvent) => void;
    onMapClick?: () => void;
    searchLocation?: Coordinate;
    isDrsMap?: boolean;
    mapLanguage?: string;
}

const superclusterOptions = {
  radius: 85,
  maxZoom: 20,
  map: (props:any) => {
    return ({
    hasDrs: props.hasDrs,
    hasMohu: props.hasMohu,
    
  })
  },
  reduce: (acc:any, props:any) => {
    acc.hasDrs = acc.hasDrs || props.hasDrs;
    acc.hasMohu = acc.hasMohu || props.hasMohu;

    return acc;
  },
}

const Map: React.FC<MapProps> = ({ 
  wastePointList,
  selectedWastePointId,
  wasteTypeList,
  searchLocation,
  onMarkerClick,
  onMapClick = () => {},
  isDrsMap = false,
  mapLanguage = "hu"
 }) => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;
    const [isDragged, setIsDragged] = useState(false);
    const [isClicked, setIsClicked] = useState(false);

    const theme = useTheme();

    const [mapBounds, setMapBounds] = useState<MapBounds | undefined>();
    const { mapsApi, setApi } = useContext(GoogleMapsApiServiceContext);
    const { location } = useContext(CurrentLocationContext);
    const getLocation = useCurrentLocation();

    // Based on: https://www.leighhalliday.com/google-maps-clustering
    const { clusters, supercluster } = useSupercluster({
        points: wastePointList.map((item) => ({
          "type": "Feature",
          "properties": {
            "cluster": false,
            "wastePointId": item.id,
            "wastePointDataTypes": Object.hasOwn(item, "types") ? (item as WastePointListWithNamesDto).types : REPONT_CONSTANT,
            "isRepontAutomated": (Object.hasOwn(item, "isRepontAutomated") && (item as WastePointListWithNamesDto).isRepontAutomated) 
                              || (Object.hasOwn(item, "isAutomaticPoint") && (item as DrsPointListAndDetailsDto).isAutomaticPoint) 
                              || (Object.hasOwn(item, "types") && (item as WastePointListDto).types?.some(t => t.toLowerCase() === AUTOMATED_REPONT_CONSTANT)),
          },
          "geometry": { "type": "Point", "coordinates": [item.coordinates?.longitude, item.coordinates?.latitude] }
        })
        ),
        bounds: mapBounds?.bounds,
        zoom: mapBounds?.zoom ?? 10,
        options: superclusterOptions,
      });

      useEffect(() => {
        if(mapsApi){
          if(!location){
            getLocation((location) => mapsApi?.mapRef && mapsApi.mapRef.panTo(new google.maps.LatLng({ lat: location.lat(), lng: location.lng() })));
          }
        }
      }, [mapsApi, location, getLocation])
      
      /**
       * @description This function is called when the map is ready
       * @param {Object} map - reference to the map instance
       * @param {Object} maps - reference to the maps library
       */
      const onGoogleApiLoaded = ({ map, maps } : {map: any, maps: MapsLibraries}) => {
        setApi(maps, map);

        google.maps.event.addListener(map,  'maptypeid_changed', () => {
          if(map.getMapTypeId() === google.maps.MapTypeId.HYBRID){
            map.setOptions({
              styles: [...mapStyleArray,  {
                "featureType": "administrative.country",
                "elementType": "geometry.stroke",
                "stylers": [
                  {
                    "color": "#ffffff"
                  },
                  {
                    "visibility": "on"
                  },
                  {
                    "weight": 3
                  }
                ]
              }]
            })
          } else {
            map.setOptions({
              styles: mapStyleArray,
            })
          }
        })
      }

      const onMapChange = ({ bounds, zoom }: { bounds: any, zoom: number }) => {
        const ne = bounds.getNorthEast()
        const sw = bounds.getSouthWest()
        setMapBounds({ ...mapBounds, bounds: [sw.lng(), sw.lat(), ne.lng(), ne.lat()], zoom })
      }

  const handleMarkerClick = (id: number, type: string, event: React.MouseEvent) => {
    setIsClicked(false)

     if(!isDragged){
        if(onMarkerClick){
          onMarkerClick(id, type.toLowerCase() === REPONT_CONSTANT ? true : false, event);
        }
        event.preventDefault();
    }
  }
  
  const getIcon = (type: string[]) => {
    if(type.length > 1){
      return wasteTypeList?.find(item => item.type === "_multiple")?.svgIcon
    }
    return wasteTypeList?.find(item => item.type === type[0])?.svgIcon
  }

  const getIsRepontMarker = (type: string[]) => {
    return type.some(t => t.toLowerCase() === REPONT_CONSTANT)
  }

  const handleClusterZoom = (clusterId: any,  longitude: number, latitude: number) => {
    if(!isDragged){
      const expansionZoom = Math.min(
        supercluster.getClusterExpansionZoom(clusterId),
        20
      );
      mapsApi?.mapRef.setZoom(expansionZoom);
      mapsApi?.mapRef.panTo({ lat: latitude, lng: longitude });
    }
    setIsClicked(false);
  }

  const mouseMoveOnMap = (event: any) => { if(isClicked){  setIsDragged(true) } }
  const mouseDownOnMap = (event: any) => { setIsClicked(true); setIsDragged(false);  }
  const mouseUpOnMap = (event: any) => {
    if(!isDragged) {
      onMapClick();
    } 
    setIsClicked(false)
  }

  return (
    <GoogleMap
        apiKey={apiKey}
        externalApiParams={{ language: mapLanguage }}
        defaultCenter={{ lat: 47.168462, lng: 19.395633 }}
        defaultZoom={8}
        options={{ ...mapOptions }}
        mapMinHeight="100vh"
        onGoogleApiLoaded={onGoogleApiLoaded}
        onChange={onMapChange}
        events={[{ 
          name: "onMouseDown",
          handler: mouseDownOnMap,
        },
        { 
          name: "onMouseMove",
          handler: mouseMoveOnMap,
        },
        {
          name: "onMouseUp",
          handler: mouseUpOnMap,
        }
      ]}
    >

        {clusters.map((cluster) => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const {
                cluster: isCluster,
                point_count: pointCount,
            } = cluster.properties;

                if(isCluster){
                    return <ClusterMarker 
                              key={`cluster-${cluster.id}`}
                              lat={latitude}
                              lng={longitude}
                              count={pointCount}
                              onMouseMove={mouseMoveOnMap}
                              onMouseDown={mouseDownOnMap}
                              markerColor={isDrsMap ? theme.palette.drsMarker.main : undefined}
                              markerBgColor={isDrsMap ? theme.palette.transparentDrsMarker.main : undefined}
                              onMarkerClick={() => handleClusterZoom(cluster.id, longitude, latitude)} />
                }
                return <WastePointMarker
                        key={`marker-${cluster.properties.wastePointId}`}
                        isSelected={selectedWastePointId === cluster.properties.wastePointId}
                        lat={latitude}
                        lng={longitude}
                        icon={getIcon(cluster.properties.wastePointDataTypes)}
                        onMouseMove={mouseMoveOnMap}
                        onMouseDown={mouseDownOnMap}
                        markerColor={isDrsMap ? theme.palette.drsMarker.main : undefined}
                        markerBgColor={isDrsMap ? theme.palette.transparentDrsMarker.main : undefined}
                        selectedColor={isDrsMap ? theme.palette.drsMarker.dark: undefined}
                        selectedBgColor={isDrsMap ? theme.palette.transparentDrsMarker.dark: undefined}
                        onMarkerClick={(event) => handleMarkerClick(cluster.properties.wastePointId, cluster.properties.wastePointDataTypes[0], event)}
                        isDrsMarker={isDrsMap}
                        isAutomatedRepont={cluster.properties.isRepontAutomated}
                        isRepontMarker={getIsRepontMarker(cluster.properties.wastePointDataTypes)}
                    />
        })}
      {searchLocation && clusters && <SearchLocationMarker iconProps={{ color: "secondary"}} zIndex={10} lat={searchLocation.latitude} lng={searchLocation.longitude} />}
    </GoogleMap>
  )
}

export default Map