import { ReactNode, useState } from "react";
import { LatLng } from "../common/types";
import { CurrentLocationContext } from "../context/CurrentLocationContext";

interface CurrentLocationProviderProps {
    children: ReactNode;
}

const CurrentLocationProvider: React.FC<CurrentLocationProviderProps> = ({ children }) => {
  const [location, setLocation] = useState<LatLng | undefined>();

  return (
    <CurrentLocationContext.Provider value={{ location, setLocation}}>
        {children}
    </CurrentLocationContext.Provider>
  )
}

export default CurrentLocationProvider