import { useCallback, useState } from "react";
import { IWastePointSearchDto, WastePointSearchDto } from "../api/app.generated";
import { REPONT_CONSTANT } from "../common/constants";

import axios from "axios";
import { useZoomOnMap } from "./useZoomOnMap";
import { useFilteredWastePoints } from "./useFilteredWastePoints";

export const useWastePointSearch = (defaultSearch: WastePointSearchDto) => {
    const { isSearchLoading, filteredWastePointList, fetchFilteredWastePoints, clearFilteredWastePointList } = useFilteredWastePoints();

    const [wastePointSearch, setWastePointSearch] = useState<WastePointSearchDto>(defaultSearch);
    
    const [searchCancelToken, setSearchCancelToken] = useState(axios.CancelToken.source());
    const { zoomDefault, zoomWithRadius } = useZoomOnMap();

    const isFilterActive = useCallback((currentSearch: IWastePointSearchDto) => {
        return (
          ((currentSearch.radiusKm ?? 0) > 0 &&
            (!!currentSearch.coordinates || !!currentSearch.routePolyLine)) ||
          (currentSearch.wasteCategories?.length ?? 0) > 0 ||
          ((currentSearch.wastePointTypes?.length ?? 0) > 0 &&
            // If categories have only REpont filtering it does not count as active filter
            !(
              currentSearch.wastePointTypes?.length === 1 &&
              currentSearch.wastePointTypes[0].toLowerCase() === REPONT_CONSTANT
            ))
        );
      }, []);

      const handleSearch = async (item: IWastePointSearchDto) => {
        const searchDto = new WastePointSearchDto({ ...wastePointSearch, ...item });
        setWastePointSearch(searchDto);
        if (
          isFilterActive(searchDto) ||
          (searchDto.wastePointTypes?.length ?? 0) > 0 ||
          searchDto.location
        ) {
          await fetchFilteredWastePoints(searchDto, searchCancelToken.token);
          zoomWithRadius(wastePointSearch);
        } else {
          // Clear search results and cancel current search request
          searchCancelToken.cancel();
          setSearchCancelToken(axios.CancelToken.source());
          clearFilteredWastePointList();
          zoomDefault();
        }
      };
    

      const searchForPolyline = useCallback(async (polyline?: string) => {
        const searchDto = new WastePointSearchDto({
          ...wastePointSearch,
          routePolyLine: polyline,
        });
        await handleSearch(searchDto);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [wastePointSearch]);

      return { wastePointSearch, filteredWastePointList, isSearchLoading, handleSearch, searchForPolyline }
}