import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
} from "react";
import {
  Coordinate,
  MapService,
  SearchFilterData,
  WastePointListWithNamesDto,
  WastePointSearchDto,
} from "../api/app.generated";
import {
  DirectionsData,
  LatLng,
  PredictionWithCoordinate,
} from "../common/types";
import DirectionsPanel from "../components/DirectionsPanel";
import Map from "../components/Map";
import {
  Badge,
  Button,
  ButtonProps,
  Grid,
  Tab,
  Tabs,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import TabPanel from "../components/TabPanel";
import SearchPanel from "../components/SearchPanel";
import { RepontIcon, SearchIcon } from "../common/Icons";
import CircularProgress from "@mui/material/CircularProgress";
import MobilePanel from "../components/MobilePanel";
import { GoogleMapsApiServiceContext } from "../context/GoogleMapsApiServiceContext";
import { CurrentLocationContext } from "../context/CurrentLocationContext";
import {
  getAutocompletPredictionForAddress,
  getAutocompletPredictionForCoordinate,
} from "../common/helpers";
import DirectionInstructions from "../components/DirectionInstructions";
import { REPONT_CONSTANT } from "../common/constants";
import { useRoutePlanning } from "../hooks/useRoutePlanning";
import { useWastePointSearch } from "../hooks/useWastePointSearch";
import { useTranslation } from "react-i18next";
import DrsDetailPanel from "../components/DrsDetailPanel";
import { useSearchParams } from "react-router-dom";

const MainContainer = styled("div")`
  width: 100%;
  display: flex;
  height: 100%;
`;
const MapPanel = styled("div")`
  position: relative;
  width: 80%;
  height: 100%;
`;

const LeftPanel = styled("div")(({ theme }) => ({
  width: "350px",
  minWidth: "350px",
  maxWidth: "350px",
  display: "flex",
  height: "100vh",
  flexDirection: "column",
  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  backgroundColor: theme.palette.background.paper,
}));

const OverviewLeftPanel = styled("div")(({ theme, hidden }) => ({
  backgroundColor: theme.palette.background.paper,
  height: "100vh",
  position: "absolute",
  left: 0,
  top: 0,
  width: "400px",
  minWidth: "400px",
  maxWidth: "400px",
  overflowY: "auto",
  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  display: hidden ? "none" : "flex",
  flexDirection: "column",
}));

const MobileButtonContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  right: "0",
  bottom: "15px",
  boxSizing: "border-box",
}));

const LoaderOverViewContainer = styled("div")(() => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "rgba(144, 144, 144, 0.3)",
  zIndex: 1000,
}));

const MobileScrollableContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  overflowY: "auto",
  background: `${theme.palette.background.paper}`,
}));

const TabContent = styled("div")(() => ({
  height: "100%",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
}));

const SearchTabContent = styled("div")(({ theme }) => ({
  height: "100%",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  background: `${theme.palette.background.paper}`,
  padding: `${theme.spacing(2)}`,
  paddingBottom: theme.spacing(1),
}));

enum TabMenuPoint {
  SearchTab = 0,
  DirectionTab = 1,
}

const MapPage: React.FC = () => {
  const { mapsApi } = useContext(GoogleMapsApiServiceContext);
  const { location } = useContext(CurrentLocationContext);

  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams(); 
  const [selectedWastePoint, setSelectedWastePoint] = useState<WastePointListWithNamesDto | null | undefined>();
  const [searchFilterOptions, setSearchFilterOptions] = useState<SearchFilterData | null>(null);
  const [searchLocation, setSearchLocation] = useState<PredictionWithCoordinate | undefined>();
  const [ isManualMachineSelected, setIsManualMachineSelected ] = useState(false);
  const [ isAutomatedMachineSelected, setIsAutomatedMachineSelected ] = useState(false);
  
  const [currentTab, setCurrentTab] = useState(0);
  const [toggleFilter, setToggleFilter] = useState(false);
  const [toggleDetails, setToggleDetails] = useState(false);  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isMobileTabsOpen, setIsMobileTabsOpen] = useState(false);
  const [mobileDirectionsStep, setMobileDirectionsStep] = useState<1 | 2>(1);
  
  const { wastePointSearch, filteredWastePointList, isSearchLoading, handleSearch, searchForPolyline } = useWastePointSearch(new WastePointSearchDto({wastePointTypes: [REPONT_CONSTANT], hideDrsPoints: false})
)
  const { isRoutesLoading, resetDirection, setDirectionsData, planRoute, directionsData } = useRoutePlanning(searchForPolyline);

  const langKey = useMemo(() => {
    if(searchParams.get("language") === "en"){
      i18n.changeLanguage("en");
      return "en"
    }
     return "hu";
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const mapService = new MapService();
    const fetch = async () => {
      const result = await mapService.getSearchFilterData();
      setSearchFilterOptions(result);

      await handleSearch(wastePointSearch);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentTab === 0) {
      resetDirection();
    } else {
      handleSearch({ ...wastePointSearch, location: undefined });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  useEffect(() => {
    if (location && !wastePointSearch.coordinates) {
      const wasteSearch = new WastePointSearchDto({
        ...wastePointSearch,
        coordinates: new Coordinate({
          latitude: location.lat(),
          longitude: location.lng(),
        }),
      });
      handleSearch(wasteSearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const onRoutePlan = async (data: DirectionsData) => {
    await planRoute(data);
    if (isMobile) setMobileDirectionsStep(2);
  }

  const handleWastePointClick = (
    itemId: number,
    isDrs: boolean,
    event?: React.MouseEvent
  ) => {
    const item = filteredWastePointList?.find(
      (item) =>
        item.id === itemId &&
        (isDrs
          ? item.types?.some(t => t.toLowerCase() === REPONT_CONSTANT)
          : item.types?.every(t => t.toLowerCase() !== REPONT_CONSTANT))
    );
    if (item) {
      setSelectedWastePoint(item);
      setToggleFilter(false);
      setToggleDetails(true);
      if (item.coordinates && mapsApi?.mapRef) {
        mapsApi.mapRef.setCenter(
          new google.maps.LatLng({
            lat: item.coordinates.latitude,
            lng: item.coordinates.longitude,
          })
        );
        mapsApi.mapRef.setZoom(Math.max(mapsApi.mapRef.zoom, 14));
      }
    }
    if (event) {
      event.stopPropagation();
    }
  };

  
  const handleRoutePlan = async (destCoord: LatLng, destAddress: string) => {
    resetDirection();
    if (currentTab !== TabMenuPoint.DirectionTab) {
      setCurrentTab(TabMenuPoint.DirectionTab);
    }

    let destPrediction = await getAutocompletPredictionForAddress(destAddress);
    if (!destPrediction) {
      destPrediction = await getAutocompletPredictionForCoordinate(destCoord);
    }
    let locationPrediction;

    if (location) {
      locationPrediction = await getAutocompletPredictionForCoordinate(
        location
      );
    }

    setDirectionsData({
      ...directionsData,
      origin: locationPrediction ?? null,
      destination: destPrediction ?? null,
    });
    if (location && destCoord) {
      await onRoutePlan({
        ...directionsData,
        origin: locationPrediction ?? location,
        destination: destPrediction ?? null,
      });
    }

    if (isMobile) {
      // close all other panels and repoen the tabbed panel
      closeMobilePanel();
      setIsMobileTabsOpen(true);
    }
  };

  const handleRouteInstructionsBack = () => {
    setMobileDirectionsStep(1);
  };

  const handleSearchValueChange = async (
    item: PredictionWithCoordinate | undefined
  ) => {
    setSearchLocation(item);
    if (item) {
      await handleSearch({
        coordinates: new Coordinate({
          latitude: item.coordinate.lat(),
          longitude: item.coordinate.lng(),
        }),
        radiusKm: wastePointSearch.radiusKm,
      });
      if(!wastePointSearch.radiusKm || wastePointSearch.radiusKm === 0){
        const circle = new google.maps.Circle({
          center: new google.maps.LatLng({ lat: item.coordinate.lat(), lng: item.coordinate.lng() }),
          radius: 10 * 1000, // radius in meter
        });
        mapsApi!.mapRef.fitBounds(circle.getBounds());
      }
    } else {
      handleSearch({
        coordinates: location
          ? new Coordinate({
              latitude: location.lat(),
              longitude: location.lng(),
            })
          : undefined,
      });
    }
  };

  const closeMobilePanel = () => {
    setIsMobileTabsOpen(false);
    setToggleDetails(false);
    setToggleFilter(false);
  };

  // nincs használva, de itt hagyom ha mégis kell
  const AutomatedMachineButton: React.FC<ButtonProps> = ({ ...props }) => (
    <>
      {isAutomatedMachineSelected ?
        <Button color="primary" sx={{ backgroundColor: "white" }} fullWidth variant="outlined" startIcon={<RepontIcon />} onClick={() => { setIsAutomatedMachineSelected(false); clearFilter()}} {...props}>
          {isMobile ? t("MACHINE_TYPE_FILTER_BUTTON.AUTOMATED_MOBILE_PUSHED") : t("MACHINE_TYPE_FILTER_BUTTON.AUTOMATED_PUSHED")}
        </Button>
        :
        <Button color="primary" fullWidth variant="contained" startIcon={<RepontIcon />} onClick={() => { setIsAutomatedMachineSelected(true); setIsManualMachineSelected(false); handleSearch({ wastePointTypes: [REPONT_CONSTANT], wasteCategories: [],  radiusKm: 0, isAutomaticPoint: true, isManualPoint: undefined }) }} {...props}>
            {isMobile ? t("MACHINE_TYPE_FILTER_BUTTON.AUTOMATED_MOBILE") : t("MACHINE_TYPE_FILTER_BUTTON.AUTOMATED")}
        </Button>
      }   
    </>
  );

    // nincs használva, de itt hagyom ha mégis kell
  const ManualMachineButton: React.FC<ButtonProps> = ({ ...props }) => (
    <>
      {isManualMachineSelected ?
        <Button color="primary" sx={{ backgroundColor: "white" }} fullWidth variant="outlined" startIcon={<RepontIcon />} onClick={() => { setIsManualMachineSelected(false); clearFilter()}} {...props}>
          {isMobile ? t("MACHINE_TYPE_FILTER_BUTTON.MANUAL_MOBILE_PUSHED") : t("MACHINE_TYPE_FILTER_BUTTON.MANUAL_PUSHED")}
        </Button>
        :
        <Button color="primary" fullWidth variant="contained" startIcon={<RepontIcon />} onClick={() => { setIsManualMachineSelected(true); setIsAutomatedMachineSelected(false); handleSearch({ wastePointTypes: [REPONT_CONSTANT], wasteCategories: [],  radiusKm: 0, isManualPoint: true, isAutomaticPoint: undefined }) }} {...props}>
            {isMobile ? t("MACHINE_TYPE_FILTER_BUTTON.MANUAL_MOBILE") : t("MACHINE_TYPE_FILTER_BUTTON.MANUAL")}
        </Button>
      }   
    </>
  );

  const clearFilter = () => {
    handleSearch({ wastePointTypes: [REPONT_CONSTANT], hideDrsPoints: false, isManualPoint: undefined, isAutomaticPoint: undefined});
  }


  const renderTabs = (
    <>
      <Tabs
        value={currentTab}
        onChange={(event, value: number) => setCurrentTab(value)}
      >
        <Tab style={{ width: "50%", fontWeight: currentTab == 0 ? "bold" : "normal" }} label={t("MAP.TABS.SEARCH")} />
        <Tab style={{ width: "50%", fontWeight: currentTab == 1 ? "bold" : "normal" }} label={t("MAP.TABS.ROUTEPLAN")} />
      </Tabs>
      <TabPanel value={currentTab} index={TabMenuPoint.DirectionTab}>
        <TabContent>
          {mapsApi?.mapsLibraries ? (
            <DirectionsPanel
            isRouteLoading={isRoutesLoading}
            onPlanRouteClick={() => onRoutePlan(directionsData)}
            resetDirection={resetDirection}
            directionsData={directionsData}
            setDirectionsData={setDirectionsData}
            isDrsPage={true}
          />
          ) : null}
        </TabContent>
      </TabPanel>
      <TabPanel value={currentTab} index={TabMenuPoint.SearchTab}>
        <SearchTabContent>
          <SearchPanel
            selectedWastePointId={selectedWastePoint?.id}
            wastePointList={filteredWastePointList ?? []}
            onWastePointClick={handleWastePointClick}
            searchValue={searchLocation}
            onSearch={handleSearchValueChange}
            isSearchLoading={isSearchLoading}
            isDrsPage={true}
            isDrsToggled={false}
          />
        </SearchTabContent>
      </TabPanel>
    </>
  );

  const renderMobileOverviewButtons = (
    <MobileButtonContainer>
      <Grid container spacing={1} padding={1} justifyContent="center">
        <Grid item xs={6}>
          <Button
            fullWidth
            color="primary"
            startIcon={<SearchIcon />}
            variant="contained"
            onClick={() => {
              setIsMobileTabsOpen(true);
            }}
          >
            {t("MAP.MOBILE.SEARCH_BUTTON")}
          </Button>
        </Grid>
      </Grid>
    </MobileButtonContainer>
  );

  const renderMobilePanels = () => {
    if (toggleDetails && selectedWastePoint) {
      return (
        <MobilePanel
          isOpen={toggleDetails && !!selectedWastePoint}
          title={t("MAP.MOBILE.SEARCH_RESULTS")}
          onClose={closeMobilePanel}
        >
          <MobileScrollableContainer>
            <DrsDetailPanel
              wastePointData={selectedWastePoint}
              onBack={() => {
                setToggleDetails(false);
                setToggleFilter(false);
                setSelectedWastePoint(undefined);
              }}
              onRoutePlanClick={handleRoutePlan}
              isDrsMap
              hideClose
            />
          </MobileScrollableContainer>
        </MobilePanel>
      );
    }

    if (isMobileTabsOpen) {
      return mobileDirectionsStep === 1 ? (
        <MobilePanel
          isOpen={isMobileTabsOpen}
          title={t("MAP.MOBILE.SEARCH_PANEL_TITLE")}
          onClose={closeMobilePanel}
        >
          {renderTabs}
        </MobilePanel>
      ) : (
        <MobilePanel
          showBackIcon
          isOpen={isMobileTabsOpen}
          title={t("MAP.MOBILE.ROUTEPLAN_TITLE")}
          onBack={handleRouteInstructionsBack}
          onClose={handleRouteInstructionsBack}
        >
          <MobileScrollableContainer sx={{ padding: theme.spacing(1) }}>
            <DirectionInstructions route={directionsData.route} />
            <Button variant="outlined" onClick={handleRouteInstructionsBack}>
              {t("MAP.MOBILE.ROUTEPLAN_BACK")}
            </Button>
          </MobileScrollableContainer>
        </MobilePanel>
      );
    }
  };

  return (
    <MainContainer>
      {isMobile ? renderMobilePanels() : <LeftPanel>{renderTabs}</LeftPanel>}
      <MapPanel style={{ width: "100%" }}>
        <Map
          wastePointList={filteredWastePointList ?? []}
          selectedWastePointId={selectedWastePoint?.id}
          onMarkerClick={handleWastePointClick}
          wasteTypeList={searchFilterOptions?.wastePointTypes}
          onMapClick={() => {
            setSelectedWastePoint(undefined);
            setToggleDetails(false);
          }}
          searchLocation={wastePointSearch.coordinates}
          isDrsMap
          mapLanguage={langKey}
        />
        {(isSearchLoading) && (
          <LoaderOverViewContainer>
            <CircularProgress disableShrink={true} size={120} />
          </LoaderOverViewContainer>
        )}

        {isMobile && renderMobileOverviewButtons}

        {!isMobile && (
          <OverviewLeftPanel hidden={!toggleFilter && !toggleDetails}>
            {toggleDetails && selectedWastePoint && (
              <DrsDetailPanel
                wastePointData={selectedWastePoint}
                onBack={() => {
                  setToggleDetails(false);
                  setToggleFilter(false);
                  setSelectedWastePoint(undefined);
                }}
                onRoutePlanClick={handleRoutePlan}
                isDrsMap
              />
            )}
          </OverviewLeftPanel>
        )}
      </MapPanel>
    </MainContainer>
  );
};

export default MapPage;
