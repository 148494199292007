import { useContext } from "react";
import { GoogleMapsApiServiceContext } from "../context/GoogleMapsApiServiceContext";
import { CurrentLocationContext } from "../context/CurrentLocationContext";
import { WastePointSearchDto } from "../api/app.generated";

export const useZoomOnMap = () => {
    const { mapsApi } = useContext(GoogleMapsApiServiceContext);
    const { location } = useContext(CurrentLocationContext);

    const zoomWithRadius = (searchDto: WastePointSearchDto) => {
        if (mapsApi &&(
          searchDto.coordinates &&
          (searchDto.radiusKm ?? 0) > 0 &&
          !searchDto.routePolyLine)
        ) {
          const circle = new google.maps.Circle({
            center: new google.maps.LatLng({
              lat: searchDto.coordinates!.latitude,
              lng: searchDto.coordinates!.longitude,
            }),
            radius: searchDto.radiusKm! * 1000, // radius in meter
          });
          mapsApi!.mapRef.fitBounds(circle.getBounds());
        } else {
          zoomDefault();
        }
      };
    
      const zoomDefault = () => {
        if(mapsApi){
          const circle = new google.maps.Circle({
            center: new google.maps.LatLng({
              lat: location ? location.lat() : 47.168462,
              lng: location ? location.lng() : 19.395633,
            }),
            radius: location ? 10000 : 100000, // 100 000 méteres körzetbe mutassuk a pineket a szűrő törlésekor
          });
          mapsApi!.mapRef.fitBounds(circle.getBounds());
        }
      };

      return { zoomDefault, zoomWithRadius }
}