import React from "react";
import { ThemeProvider } from "@mui/material";
import { defaultTheme } from "./themes/theme";
import ErrorMessageHandler from "./components/ErrorMessageHandler";
import GoogleMapsApiServiceProvider from "./components/GoogleMapsApiServiceProvider";
import CurrentLocationProvider from "./components/CurrentLocationProvider";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes";

import "./localization/i18next.ts";

const App: React.FC = () => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <ErrorMessageHandler>
        <GoogleMapsApiServiceProvider>
          <CurrentLocationProvider>
            <RouterProvider router={router} />
          </CurrentLocationProvider>
        </GoogleMapsApiServiceProvider>
      </ErrorMessageHandler>
    </ThemeProvider>
  );
};

export default App;
