import { Card, CardProps, Divider, Grid, Typography, styled } from '@mui/material'
import React from 'react'
import { WastePointListWithNamesDto } from '../api/app.generated'
import { useTranslation } from 'react-i18next';

export interface WastePointListItemProps {
    wastePointItem: WastePointListWithNamesDto,
    isSelected: boolean,
}

interface StyledCardProps extends CardProps {
     isSelected: boolean;
}

const StyledCard = styled(Card, {
        shouldForwardProp: (prop) => prop !== "isSelected"
    })<StyledCardProps>(({theme, isSelected}) => ({
        padding: theme.spacing(4),
        borderRadius: "12px",
        border: "1px solid #DCDCDC",
        ":hover": {
            cursor: "pointer",
            backgroundColor: "#ECECEC"
        },
        boxShadow: "none",
        backgroundColor: isSelected ? "#ECECEC" : 'white',
}))

const WastePointListItem: React.FC<WastePointListItemProps> = ({ wastePointItem, isSelected }) => {  
    const { t } = useTranslation();

    return (
    <StyledCard isSelected={isSelected}>
        <Grid container direction="column" spacing={1}>
            <Grid item>
                <Typography variant='title'>{wastePointItem.name}</Typography>
            </Grid>
            <Grid item>
                <Divider />
            </Grid>
            <Grid item>
                <Grid container justifyContent="space-between">
                    <Grid item md={3}><Typography variant='subtitle1'>{t("SEARCH_PANEL.CARDS.ADDRESS")}:</Typography></Grid>
                    <Grid item md={9}>
                        <Typography variant='body2'>
                            {`${wastePointItem.zipCode} ${wastePointItem.city}, ${wastePointItem.address}`}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container justifyContent="space-between">
                    <Grid item md={3}>
                        <Typography variant='subtitle1'>{t("SEARCH_PANEL.CARDS.TYPE")}:</Typography>
                    </Grid>
                    <Grid item md={9}>
                        <Typography variant='body2'>{wastePointItem.types?.join("; ") ?? "REpont"}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </StyledCard>
  )
}

export default WastePointListItem