
import { Checkbox, Grid, IconButton, Stack, Typography, styled } from '@mui/material'
import React from 'react'
import { WasteCategoryDto, WasteSubCategoryDto } from '../api/app.generated';
import { ChevronRight, KeyboardArrowDown } from '@mui/icons-material';

export interface CategoryListItemProps {
    item: WasteCategoryDto;
    isExpanded: boolean;
    isChecked: boolean;
    selectedSubCategoryIds?: number[];
    toggleExpand: (id: number) => void;
    handleCategoryCheck?: (category: WasteCategoryDto) => void;
    handleSubCategoryCheck?: (category: WasteCategoryDto, subCategory: WasteSubCategoryDto) => void;
}

export const CATEGORY_ROW_HEIGHT = 52; 

const StyledListItem = styled("div")(({ theme }) => ({
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: "12px",
    "&:hover": {
        cursor: "pointer"
    },
    maxHeight: CATEGORY_ROW_HEIGHT
}))

const StyledSubCategory = styled(Grid)(({ theme }) => ({
    "&:hover": {
        cursor: "pointer"
    },
    paddingLeft: theme.spacing(1)
}))

const StyledDropdownIconButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.black,
    padding: "3px",
}))

const CategoryListItem: React.FC<CategoryListItemProps> = ({ item, isChecked, isExpanded, selectedSubCategoryIds, toggleExpand, handleCategoryCheck,  handleSubCategoryCheck }) => {
  return (
    <>
    <StyledListItem>
            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={2} onClick={handleCategoryCheck ? () => handleCategoryCheck(item) : undefined }>
                    <Checkbox indeterminate={(selectedSubCategoryIds?.length ?? 0) > 0 && selectedSubCategoryIds?.length !== item.subCategories?.length} checked={isChecked}  color="primary"  />
                </Grid>
                <Grid item xs={8} onClick={ handleCategoryCheck? () => handleCategoryCheck(item) : undefined}>
                    <Typography variant='body1'>{item.name}</Typography>
                </Grid>
                { (item.subCategories?.length ?? 0) > 0 &&
                    <Grid item container xs={2} alignItems="center" justifyContent="center" onClick={() => toggleExpand(item.id)}>
                        <StyledDropdownIconButton size='small' disableRipple>
                            { isExpanded ? <KeyboardArrowDown /> : <ChevronRight /> }
                        </StyledDropdownIconButton>
                </Grid> }
            </Grid>
        </StyledListItem>
        {(isExpanded && (item.subCategories?.length ?? 0) > 0) &&
            <Stack direction="column">
                {item.subCategories?.map((subCat, sIndex) => 
                    <StyledSubCategory key={`subcategory-${sIndex}`} container alignItems="center">
                        <Grid item xs={2}>
                            <Checkbox checked={selectedSubCategoryIds?.includes(subCat.id) ?? false} color="primary" onClick={handleSubCategoryCheck ? () => handleSubCategoryCheck(item, subCat) : undefined }  />
                        </Grid>
                        <Grid item xs={10} onClick={handleSubCategoryCheck ? () => handleSubCategoryCheck(item, subCat) : undefined}>
                            <Typography variant='subtitle1'>{subCat.name}</Typography>
                        </Grid>
                    </StyledSubCategory>
                )}
            </Stack>}
        </>
  )
}

export default CategoryListItem


