import React, { ReactNode, useState } from 'react'
import { ErrorMessageContext } from '../context/ErrorMessageContext';
import { Alert, AlertTitle, Snackbar } from '@mui/material';
import { ErrorMessage } from '../common/types';

interface ErrorMessageHandlerProps {
    children: ReactNode;
}

const ErrorMessageHandler: React.FC<ErrorMessageHandlerProps> = ({ children }) => {
    const [errorMessage, setErrorMessage] = useState<ErrorMessage | undefined>();

    const handleClose = () => {
        setErrorMessage(undefined);
    }
  return (
    <ErrorMessageContext.Provider value={{ message: errorMessage, setErrorMessage }}>
        { errorMessage &&
        <Snackbar
            open={true}
            anchorOrigin={{ vertical: "top", horizontal: "right"}}
            autoHideDuration={6000}
            onClose={handleClose}
            >
            <Alert variant='filled' onClose={handleClose} severity={errorMessage.severity ?? "error"} sx={{ width: '100%' }}>
                <AlertTitle>{errorMessage.title}</AlertTitle>
                {errorMessage.message}
            </Alert>
        </Snackbar>}
        {children}
    </ErrorMessageContext.Provider>
  )
}

export default ErrorMessageHandler