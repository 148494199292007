import { Button,  Chip,  ChipProps,  Grid,  IconButton,  Slider, Stack, Tab, Tabs, TextField, Typography, styled, useMediaQuery, useTheme } from '@mui/material'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import {  SearchFilterData, WastePointSearchDto } from '../api/app.generated';
import CategoryList from './CategoryList';
import { Clear, KeyboardArrowUp } from '@mui/icons-material';
import { FilterValue, LatLng, SelectedCategory } from '../common/types';
import { CurrentLocationContext } from '../context/CurrentLocationContext';
import TabPanel from './TabPanel';
import { CATEGORY_ROW_HEIGHT } from './CategoryListItem';
import { REPONT_CONSTANT } from '../common/constants';
import { getFilterDataFromSearch } from '../common/helpers';

const MAX_RADIUS_IN_KM = 100;

export interface SearchPanelProps {
    onFilter: (value: FilterValue) => void;
    currentSearch: WastePointSearchDto;
    onClose: () => void;
    onClearFilter: () => void;
    searchFilterOptions: SearchFilterData | null;
    searchLocation?: LatLng;
    hasRoute?: boolean;
}

export interface TypeChipProps extends ChipProps { 
  selected: boolean 
};

export const defaultFilter: FilterValue = {
  radiusKm: 0,
  wasteCategories: []
}

const TypeChip = styled(Chip)<TypeChipProps>(({ theme, selected }) => ({
  backgroundColor: selected ? theme.palette.primary.main : "white",
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(1),
  ".MuiTouchRipple-root" : {
    backgroundColor: selected ? theme.palette.primary.main : "white",
  },
  ".MuiChip-label" : {
    zIndex: 10
  }
}))

const ContentStack = styled(Stack)(({ theme }) => ({
  overflow: "auto",
  position: "relative",
  boxSizing: "border-box"
}));

const TitleRow = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center" 
}));

const CollapseButton = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  "&:hover": {
    cursor: "pointer",
    background: theme.palette.grey[300]
  }
}));

export const StickyButtonContainer = styled(Stack)(({ theme }) => ({
   position: "sticky",
   bottom: 0,
   left: 0,
   background: theme.palette.background.paper,
   width: "100%",
   boxSizing: "border-box",
   marginTop: "auto"
  }
));

const SliderContainer = styled("div")(({ theme }) => ({
  paddingLeft: "10px",
  paddingRight: "25px",
  marginBottom: theme.spacing(1)
}));

const SmallNumberField = styled(TextField)(({ theme }) => ({
  "&  .MuiInputBase-input": {
    paddingTop: "8px",
    paddingBottom: "8px"
  }
}));

enum TabMenuPoint {
  CategoryFilterTab = 0,
  WasteTypeFilterTab = 1,
  RePontFilterTab = 2
}

const SLIDER_AND_BUTTON_HEIGHT = 200;

const FilterPanel: React.FC<SearchPanelProps> = 
  ({ 
    onFilter,
    onClose,
    onClearFilter,
    currentSearch,
    searchFilterOptions,
    searchLocation,
    hasRoute = false,
  }) => {
  const [filterValue, setFilterValue] = useState<FilterValue>(defaultFilter);
  const [showAllCategory, setShowAllCategory] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState(0);
  const categoryPanelRef = useRef<HTMLDivElement | null>(null);
  const [maxCategoryCount, setMaxCategoryCount] = useState(1);


  const { location } = useContext(CurrentLocationContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if(searchFilterOptions){
      setFilterValue(getFilterDataFromSearch(currentSearch, searchFilterOptions))
    }
  }, [currentSearch, searchFilterOptions])

  const clearFilter = () => {
    onClearFilter()
  }
  
   useEffect(() => {
    const elements = (searchFilterOptions?.categories ?? []).slice(0, maxCategoryCount);
    
    if(filterValue?.wasteCategories.some(i => !(elements.some(e => e.name?.toLowerCase() === i.name.toLowerCase() 
                                        || e.subCategories?.some(sc => sc.name?.toLowerCase() === i.name.toLowerCase())))))
    {
      setShowAllCategory(true);
    }
  }, [searchFilterOptions, filterValue, maxCategoryCount])

  const onCategoriesUpdated = (result: SelectedCategory[]) => {
    setFilterValue({ ...filterValue, wasteCategories: result })
  }
  const handleFilterClick = () => {
    if(filterValue){
      onFilter(filterValue);
    }
  }
  const handleShowAll = () => {
    setShowAllCategory(!showAllCategory);
  }
  const toggleType = (typeStr: string) => {
    if(filterValue?.wastePointTypes?.includes(typeStr)){
      setFilterValue({ ...filterValue, wastePointTypes: [...filterValue.wastePointTypes.filter(item => item !== typeStr)]});
    } else {
      setFilterValue({ ...filterValue, wastePointTypes: [...(filterValue?.wastePointTypes ?? []), typeStr] });
    }
  }

  const sliderLabel = useMemo(() => {
    if(hasRoute)
      return "Távolság a tervezett út körül:"
    if (searchLocation && 
      !(searchLocation.lat() === location?.lat() &&
        searchLocation.lng() === location?.lng())) {
      return "Távolság a keresett helytől:"
    } else if(location){
          return "Távolság a jelenlegi helyzettől:"
    } else 
    return "Távolság (ha van keresett hely vagy útvonal):"
  }, [location, hasRoute, searchLocation]);

  useEffect(() => {
    const radius = filterValue.radiusKm;
    var currentFilter = getFilterDataFromSearch(currentSearch, searchFilterOptions!);
    if(currentTab === TabMenuPoint.CategoryFilterTab){
      setFilterValue({ wasteCategories: currentFilter.wasteCategories ?? [], wastePointTypes: [], radiusKm: radius });
    }
    if(currentTab === TabMenuPoint.WasteTypeFilterTab){
      setFilterValue({ 
        wasteCategories: [],
        wastePointTypes: currentFilter.wastePointTypes?.length === 1 &&
                         currentFilter.wastePointTypes?.every(i => i === REPONT_CONSTANT) ? [] : currentFilter.wastePointTypes,
        radiusKm: radius });
    }
    if(currentTab === TabMenuPoint.RePontFilterTab){
      setFilterValue({ wasteCategories: [], wastePointTypes: [REPONT_CONSTANT], radiusKm: radius });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab])

  const handlResize = (e: ResizeObserverEntry[]) => {
    const count = Math.floor((e[0].contentBoxSize[0].blockSize - SLIDER_AND_BUTTON_HEIGHT) / CATEGORY_ROW_HEIGHT);
    setMaxCategoryCount(count);
  }

  useEffect(() => {
    if(categoryPanelRef.current){
      const observer = new ResizeObserver(handlResize);
      const refValue = categoryPanelRef.current;
      observer.observe(refValue);
      return () => { observer.unobserve(refValue)  };
    }
  }, [categoryPanelRef])

  const sliderComponent = 
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={8}>
          <Typography variant='subtitle1'>{sliderLabel}</Typography>
        </Grid>
        <Grid item xs={3}>
          <SmallNumberField disabled type="number" value={filterValue.radiusKm ?? 0} InputProps={{ endAdornment: "km" }} />
        </Grid>
      </Grid>
      <SliderContainer>
        <Slider 
          min={0}
          max={MAX_RADIUS_IN_KM}
          disabled={!location && !searchLocation && !hasRoute}
          sx={{ margin: "10px"}}
          marks={[{ label: "0 km", value: 0}, { label: `${MAX_RADIUS_IN_KM} km`, value: MAX_RADIUS_IN_KM}]}
          valueLabelDisplay="auto"
          value={filterValue.radiusKm ?? 0}
          onChange={(_, num) => { setFilterValue({ ...filterValue, radiusKm: num as number})}} 
          />
      </SliderContainer>
    </>;

  return (
    <>
      <TitleRow>
        <Typography variant='title'>Szűrő</Typography>
        { !isMobile && <IconButton onClick={() => onClose()} ><Clear /></IconButton> }
      </TitleRow>
      <Tabs  value={currentTab} onChange={(event, value: number) => setCurrentTab(value)} >
        <Tab style={{ width: "33%" }} label="Leadható hulladék" />
        <Tab style={{ width: "33%" }} label="Leadóhely típusa" />
        <Tab style={{ width: "33%" }} label="REpontok" />
      </Tabs>
      <TabPanel style={{ overflow: "auto", height: "100%" }} innerElementProps={{ ref: categoryPanelRef }} value={currentTab} index={TabMenuPoint.CategoryFilterTab}>
        <ContentStack direction="column" spacing={1} padding={1} >
          <CategoryList 
            categories={searchFilterOptions?.categories ?? []}
            selectedCategories={filterValue.wasteCategories}
            updateSelectedCategories={onCategoriesUpdated}
            showAll={showAllCategory}
            maxCategoryCount={maxCategoryCount} />
          { showAllCategory ? 
            <CollapseButton onClick={handleShowAll}>
              <KeyboardArrowUp color="primary" />
            </CollapseButton>
            : 
            <Button fullWidth variant="outlined" onClick={handleShowAll}>Összes mutatása</Button> }

            {sliderComponent}
          </ContentStack>
        </TabPanel>
        <TabPanel style={{ overflow: "auto" }} value={currentTab} index={TabMenuPoint.WasteTypeFilterTab}>
          <ContentStack direction="column" spacing={1} padding={1}>
            <Typography variant='subtitle1'>Leadóhely típusa</Typography>
            <Stack direction="row" flexWrap="wrap">
              {searchFilterOptions?.wastePointTypes?.filter(t => !t.isHidden).map((type, index) => 
                <TypeChip
                  key={`waste-type-${index}`}
                  variant='outlined'
                  selected={filterValue.wastePointTypes?.some(item => type.type?.toLowerCase()! === item.toLowerCase()) ?? false}
                  label={type.type}
                  onClick={() => toggleType(type.type!)}></TypeChip>)}
            </Stack>
            {sliderComponent}
          </ContentStack>
        </TabPanel>
        <TabPanel style={{ overflow: "auto" }} value={currentTab} index={TabMenuPoint.RePontFilterTab}>
          <ContentStack direction="column" spacing={1} padding={1}>
            <Typography variant='body1'>Csak REpont visszaváltó helyek megjelenítése</Typography>
            
            {sliderComponent}
          </ContentStack>
        </TabPanel>
        <StickyButtonContainer direction="column" spacing={1} padding={1}>
              <Button variant='outlined' onClick={() => { clearFilter() } }>Szűrők törlése</Button>
              <Button variant='contained' onClick={handleFilterClick}>Alkalmazás</Button>
        </StickyButtonContainer> 
      </>
  )
}

export default FilterPanel