import React, { useState } from 'react'
import { WasteCategoryDto, WasteSubCategoryDto } from '../api/app.generated'
import { Stack } from '@mui/material';
import CategoryListItem from './CategoryListItem';
import { SelectedCategory } from '../common/types';

export interface CategoryListProps {
    categories: WasteCategoryDto[];
    selectedCategories: SelectedCategory[];
    updateSelectedCategories: (category: SelectedCategory[]) => void;
    maxCategoryCount: number;
    showAll: boolean;
}

const CategoryList: React.FC<CategoryListProps> = ({ categories, selectedCategories, updateSelectedCategories, maxCategoryCount, showAll = true }) => {
    const [expandedIds, setExpandedIds] = useState<number[]>([]);

    const handleCategoryChecked = (category: WasteCategoryDto) => {
        if(selectedCategories.some(it => it.categoryId === category.id)){
            updateSelectedCategories(selectedCategories.filter(it => it.categoryId !== category.id));
        } else {
            updateSelectedCategories(
                [...selectedCategories, 
                    { categoryId: category.id,
                      name: category.name!,
                      selectedSubCategories: category.subCategories?.map(sc => ({ id: sc.id, name: sc.name!})) ?? []  
                    }
                ]);
        }
    }

    const handleSubCategoryChecked = (category: WasteCategoryDto, subCategory: WasteSubCategoryDto) => {
        const cat = selectedCategories.find(it => it.categoryId === category.id);
        if(cat){
            const idx = cat.selectedSubCategories.findIndex(sc => sc.id === subCategory.id);
            if(idx > -1){
                cat.selectedSubCategories.splice(idx, 1);
            } else {
                cat.selectedSubCategories = [...cat.selectedSubCategories, { id: subCategory.id, name: subCategory.name! }];
            }
            const updatedArray = cat.selectedSubCategories.length > 0 ?
                    [...selectedCategories.filter(it => it.categoryId !== category.id), cat] 
                    :
                    [...selectedCategories.filter(it => it.categoryId !== category.id)]
            updateSelectedCategories(updatedArray);
        } else {
            updateSelectedCategories(
                [...selectedCategories, 
                    { categoryId: category.id,
                      name: category.name!,
                      selectedSubCategories: [{ id: subCategory.id, name: subCategory.name! }]  
                    }
                ]);
        }
    }

    const toggleExpand = (id: number) => {
        const selectedIndex =  expandedIds.findIndex((item) => item === id);
        if(selectedIndex > -1){
            const arr = expandedIds;
            arr.splice(selectedIndex, 1)
            setExpandedIds([...arr]);
        } else {
            setExpandedIds([...expandedIds, id]);
        }
    }

    const renderCategories = () => categories.map((item, index) => 
        <CategoryListItem 
            key={`category-${index}`}
            item={item}
            isChecked={selectedCategories.some(it => item.id === it.categoryId)}
            selectedSubCategoryIds={selectedCategories.find(it => item.id === it.categoryId)?.selectedSubCategories.map(sc => sc.id)}
            isExpanded={expandedIds.includes(item.id)}
            toggleExpand={toggleExpand}
            handleCategoryCheck={handleCategoryChecked}
            handleSubCategoryCheck={handleSubCategoryChecked} />
    )
  
  return (
        <Stack direction="column" spacing={1}>
            {showAll ? renderCategories() : renderCategories().slice(0, maxCategoryCount)}
        </Stack>
  )
}

export default CategoryList