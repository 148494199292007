import React from 'react'
import { WastePointListWithNamesDto } from '../api/app.generated'
import WastePointListItem from './WastePointListItem'
import { styled } from '@mui/material'
import { Stack } from '@mui/system'
import { REPONT_CONSTANT } from '../common/constants'

export interface WastePointListPanelProps {
    wastePoints: WastePointListWithNamesDto[];
    selectedWastePointId?: number;
    onWastePointClick: (itemId: number, isDrs: boolean) => void;
}

const StyledContainer = styled(Stack)(({theme}) => ({ 
  background: `${theme.palette.background.paper}`,
  overflowY: "auto",
  flexWrap: "nowrap",
  padding: "2px"
}))

const WastePointListPanel: React.FC<WastePointListPanelProps> = ({ wastePoints, onWastePointClick, selectedWastePointId }) => {
  return (
    <StyledContainer direction="column" spacing={2}>
        {wastePoints.map((item) => (
        <div key={item.id} onClick={() => onWastePointClick(item.id, (item.hasOwnProperty("types") ? item.types?.some(t => t.toLowerCase() === REPONT_CONSTANT) ?? false : true))}>
          <WastePointListItem wastePointItem={item} isSelected={selectedWastePointId === item.id} />
        </div>))}
    </StyledContainer>
  )
}

export default WastePointListPanel