import React, { useContext, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';
import { AutocompletePrediction } from '../common/types';
import { InputAdornment, styled } from '@mui/material';
import { NavArrowIcon } from '../common/Icons';
import { GoogleMapsApiServiceContext } from '../context/GoogleMapsApiServiceContext';
import { useTranslation } from 'react-i18next';

export interface AutocompletePlaceInputProps {
  onSelectResult?:  (result: AutocompletePrediction | null) => void;
  inputProps?: TextFieldProps;
  value: AutocompletePrediction | null
}

const StyledTextField = styled(TextField)<TextFieldProps>(() => ({
  "& .MuiInputBase-root": {
    borderRadius: "10px",
    paddingRight: "10px !important"
  },
}))

const AutocompletePlaceInput: React.FC<AutocompletePlaceInputProps> = ({ value, onSelectResult, inputProps }) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<readonly AutocompletePrediction[]>([]);
  const [service, setService] = useState<google.maps.places.AutocompleteService | undefined>(undefined);
  const { mapsApi } = useContext(GoogleMapsApiServiceContext);

  const fetch = useMemo(
    () =>
      debounce(
        (
          request: { input: string, componentRestrictions?: google.maps.places.ComponentRestrictions; },
          callback: (results?: readonly AutocompletePrediction[] | null) => void,
        ) => {
          service?.getPlacePredictions(
            request,
            callback,
          );
        },
        400,
      ),
    [service],
  );

  useEffect(() => {
    let active = true;

    if (!service && mapsApi) {
      setService(new mapsApi.mapsLibraries.places.AutocompleteService());
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue, componentRestrictions: {"country": "hu"} }, (results?: readonly AutocompletePrediction[] | null) => {
      if (active) {
        let newOptions: readonly AutocompletePrediction[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [service, value, inputValue, fetch, mapsApi]);

  return (
    <Autocomplete
      id="google-place-autocomplete"
      sx={{ width: '100%' }}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText={t("AUTOCOMPLETE.NO_RESULT")}
      onChange={(event: any, newValue: AutocompletePrediction | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        if(onSelectResult){
          onSelectResult(newValue);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <StyledTextField {...params} 
          {...inputProps} 
          InputProps={{ ...params.InputProps, startAdornment: (<InputAdornment position='start'><NavArrowIcon color='primary' /></InputAdornment>), ...inputProps?.InputProps }} 
          fullWidth
          />
      )}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings || [];

          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match: any) => [match.offset, match.offset + match.length]),
          );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <LocationOnIcon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                {parts.map((part:any, index: number) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}

export default AutocompletePlaceInput;