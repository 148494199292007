import { CircularProgress, InputAdornment, Typography, styled, useMediaQuery } from '@mui/material'
import React, { useContext, useMemo } from 'react'
import WastePointListPanel from './WastePointListPanel';
import { WastePointListWithNamesDto } from '../api/app.generated';
import { Stack, useTheme } from '@mui/system';
import { SearchIcon } from '../common/Icons';
import { AutocompletePrediction, PredictionWithCoordinate, } from '../common/types';
import AutocompletePlaceInput from './AutocompletePlaceInput';
import { GoogleMapsApiServiceContext } from '../context/GoogleMapsApiServiceContext';
import { ErrorMessageContext } from '../context/ErrorMessageContext';
import { useTranslation } from 'react-i18next';

export interface SearchPanelProps {
    wastePointList: WastePointListWithNamesDto[];
    selectedWastePointId?: number;
    isSearchLoading: boolean;
    isDrsPage: boolean;
    isDrsToggled: boolean;
    searchValue: PredictionWithCoordinate | undefined;
    onSearch: (value: PredictionWithCoordinate | undefined) => void;
    onWastePointClick: (itemId: number, isDrs: boolean) => void;
}

const LoaderContainer = styled('div')(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}))

const SearchPanel: React.FC<SearchPanelProps> = ({ searchValue, onSearch, wastePointList, onWastePointClick, selectedWastePointId, isSearchLoading, isDrsPage: isDrsPage, isDrsToggled: isDrsToggled }) => {
  const { mapsApi } = useContext(GoogleMapsApiServiceContext);
  const { setErrorMessage } = useContext(ErrorMessageContext);

  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderList = useMemo(() => (
    <WastePointListPanel selectedWastePointId={selectedWastePointId} wastePoints={wastePointList} onWastePointClick={onWastePointClick} />),
   // eslint-disable-next-line react-hooks/exhaustive-deps
   [wastePointList, selectedWastePointId])

  const getCoordinate = async (prediction: AutocompletePrediction) => {
    if(mapsApi?.geocoder){
      const result = await mapsApi.geocoder.geocode(({ placeId: prediction.place_id }));
      if(result.results[0]){
        onSearch({ prediction, coordinate: result.results[0].geometry.location });
      } else {
        setErrorMessage({ severity: "warning", title: t("SEARCH_PANEL.LOCATION_WARNING.TITLE"),  message: t("SEARCH_PANEL.LOCATION_WARNING.MESSAGE") })
      }
    }
  }
  
  return (
    <>
        <Stack direction="column" spacing={2} marginY={2}>
          <AutocompletePlaceInput
            value={searchValue?.prediction ?? null}
            onSelectResult={(result: AutocompletePrediction | null) => { 
              if(result){ 
                getCoordinate(result) 
              } else {
                onSearch(undefined)
              }; }}
            inputProps={{
              placeholder: t("SEARCH_PANEL.SEARCH_PLACEHOLDER"),
              InputProps: { 
                startAdornment: 
                  <InputAdornment position='start'><SearchIcon color='primary' /></InputAdornment>
            }}}
          />
          {!isDrsPage && !isDrsToggled && <Typography variant='title'>{ t("SEARCH_PANEL.RESULTS", { value: wastePointList.length })}</Typography>}
        </Stack>
        {isSearchLoading && !isMobile ? <LoaderContainer><CircularProgress disableShrink={true} /></LoaderContainer> : (!isDrsPage && !isDrsToggled && renderList)}
    </>
  )
}

export default SearchPanel