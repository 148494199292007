import { createBrowserRouter } from "react-router-dom";
import MapPage from "./pages/MapPage";
import DrsMapPage from "./pages/DrsMapPage";
import { ThemeProvider } from "@mui/material";
import { drsTheme } from "./themes/theme";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <MapPage />,
  },
  {
    path: "/drs",
    element: <ThemeProvider theme={drsTheme}>
        <DrsMapPage />
      </ThemeProvider>
  },
]);
