import { useMemo, useState } from "react";
import { MapService, WastePointListWithNamesDto, WastePointSearchDto } from "../api/app.generated";
import { debounce } from "@mui/material";
import { CancelToken, CanceledError } from "axios";

export const useFilteredWastePoints = () => {
    const [filteredWastePointList, setFilteredWastePointList] = useState<WastePointListWithNamesDto[] | undefined>(undefined);
    const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false);

    const clearFilteredWastePointList = () => {
        setFilteredWastePointList(undefined);
    }

    const fetchFilteredWastePoints = useMemo(() => {
        const mapService = new MapService();
        return debounce(
          async (
            wastePointSearch: WastePointSearchDto,
            cancelToken: CancelToken
          ) => {
            try {
              setIsSearchLoading(true);
              const result = await mapService.searchWastePoints(
                wastePointSearch,
                cancelToken
              );
              setIsSearchLoading(false);
              setFilteredWastePointList(result ?? []);
            } catch (e) {
              setIsSearchLoading(false);
              if (e instanceof CanceledError) {
                return;
              }
              throw e;
            }
          },
          400
        );
      }, []);
    
    return { isSearchLoading, filteredWastePointList, fetchFilteredWastePoints, clearFilteredWastePointList }
}