import { useContext, useState } from "react";
import { DirectionsData, DirectionsRequest, DirectionsResult, DirectionsStatus } from "../common/types";
import { GoogleMapsApiServiceContext } from "../context/GoogleMapsApiServiceContext";

const defaultDirectionsData: DirectionsData = {
    origin: null,
    destination: null,
    route: null,
    waypoints: [],
    routeAvoidOptions: {
      ferries: false,
      tolls: false,
      highways: false,
    },
  };

export const useRoutePlanning = (searchForRoute: (routePolyline?: string) => Promise<void>) => {
    const { mapsApi } = useContext(GoogleMapsApiServiceContext);  
    const [isRoutesLoading, setIsRoutesLoading] = useState<boolean>(false);
    const [defaultDestination, setDefaultDestination] = useState<string | undefined>();
    const [directionsData, setDirectionsData] = useState<DirectionsData>(defaultDirectionsData);

    const planRoute = async (data: DirectionsData) => {
        setIsRoutesLoading(true);
        const request: DirectionsRequest = {
          origin:
            data.origin instanceof google.maps.LatLng
              ? data.origin
              : data.origin?.description ?? "",
          destination:
            data.destination instanceof google.maps.LatLng
              ? data.destination
              : data.destination?.description ?? "",
          travelMode: google.maps.TravelMode.DRIVING,
          waypoints: data.waypoints
            .filter((item) => item !== null)
            .map((item) => ({ location: item?.description, stopover: true })),
          avoidHighways: data.routeAvoidOptions.highways,
          avoidFerries: data.routeAvoidOptions.ferries,
          avoidTolls: data.routeAvoidOptions.tolls,
        };
        await mapsApi?.directionsService?.route(request, (
            result: DirectionsResult | null,
            status: DirectionsStatus) => {
          displayDirection(result, status);
          setDirectionsData({ ...data, route: result });
        });
        setIsRoutesLoading(false);
      };

      const displayDirection = async (
        result: DirectionsResult | null,
        status: DirectionsStatus
      ) => {
        if (status === "OK" && mapsApi?.directionsRenderer) {
          mapsApi.directionsRenderer.setDirections(result);
          await searchForRoute(result?.routes[0].overview_polyline ?? "");
        }
      };
    
      const resetDirection = async () => {
        if (mapsApi?.directionsRenderer) {
          mapsApi.directionsRenderer.set("directions", null);
          await searchForRoute(undefined);
        }
        if (defaultDestination) {
          setDefaultDestination(undefined);
        }
        setDirectionsData(defaultDirectionsData);
      };

      return {
        isRoutesLoading, 
        resetDirection,
        displayDirection,
        planRoute,
        directionsData,
        setDirectionsData
     }
}