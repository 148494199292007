import { Coordinate, IWasteCategoryDto, SearchFilterData, WastePointSearchDto } from "../api/app.generated";
import { AutocompletePrediction, FilterValue, LatLng, SelectedCategory } from "./types";

export const predictionFromCoordinate = (coord: LatLng): AutocompletePrediction => {
    const coordString = `${coord.lat()} ${coord.lng()}`;

   return {
      description: coordString,
      place_id: "coordinates",
      matched_substrings: [],
      structured_formatting: { 
        main_text: coordString,
        main_text_matched_substrings: [],
        secondary_text: coordString  
      },
      terms: [],
      types: []
    }
}

export const getAutocompletPredictionForCoordinate = async (coord: LatLng): Promise<AutocompletePrediction | undefined> => {
    const coder = new google.maps.Geocoder();
    const service = new google.maps.places.AutocompleteService();
    let result = predictionFromCoordinate(coord);
    
    const results = await coder.geocode({ location: coord });
    
      if(results && results.results[0]){
       const predictions = await service.getPlacePredictions({ input: results.results[0].formatted_address });
      
        if(predictions && predictions.predictions[0]){
          result = predictions.predictions[0];
          }
      }
    
    return result;
  }

export const getAutocompletPredictionForAddress = async (address: string): Promise<AutocompletePrediction | undefined> => {
    const service = new google.maps.places.AutocompleteService();
    const predictions = await service.getPlacePredictions({ input: address });
    let result;

    if(predictions && predictions.predictions[0]){
      result = predictions.predictions[0];
    }

    return result;
}

export const convertQueryParamsToWastePointSearch = (searchParams: URLSearchParams): WastePointSearchDto | undefined => {
  if(searchParams.size <= 0){
    return undefined;
  }
  const types = searchParams.getAll("type")
  const categories = searchParams.getAll("category")
  const latitude = searchParams.get("lat")
  const longitude = searchParams.get("lon")
  const address = searchParams.get("address")
  const radiusKm = searchParams.get("radius")


  return new WastePointSearchDto({
    wastePointTypes: types ?? undefined,
    wasteCategories: categories ?? undefined,
    location: address ?? undefined,
    radiusKm: radiusKm ? +radiusKm : undefined,
    coordinates: latitude && longitude ? new Coordinate({ latitude: +latitude, longitude: +longitude }) : undefined,
  })
}

export const getFilterDataFromSearch = (searchData: WastePointSearchDto, searchFilterOptions: SearchFilterData): FilterValue => {
  const searchedCategories = searchData.wasteCategories?.map(wc => wc.toLowerCase().trim()) ?? [];
  var foundCategories = searchFilterOptions?.categories?.reduce((acc: IWasteCategoryDto[], item) => {
    let subCatArray = [];
    subCatArray = item.subCategories?.filter(i => searchedCategories.includes(i.name!.toLowerCase())) ?? [];

    if(searchedCategories.includes(item.name!.toLowerCase().trim())){
      subCatArray = item.subCategories ?? [];
      acc.push(item);
      return acc;
    }

    if(subCatArray.length > 0){
      acc.push({ ...item, subCategories: subCatArray })
    }
    return acc;
  }, []);

  const filterCategories = foundCategories?.map<SelectedCategory>(
  category => ({ name: category.name!, 
              categoryId: category.id, 
              selectedSubCategories: category.subCategories?.map(subc => ({
                  id: subc.id,
                  name: subc.name! })) ?? []
            })) ?? [];
  const filterVal: FilterValue = {
        wasteCategories: filterCategories,
        wastePointTypes: searchData.wastePointTypes,
        radiusKm: searchData.radiusKm,
  }
  return filterVal;
}