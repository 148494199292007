import { ArrowBack, Clear } from '@mui/icons-material';
import { Divider, IconButton, Stack, Typography, styled } from '@mui/material';
import React, { ReactElement } from 'react'

interface MobilePanelProps {
    isOpen: boolean;
    title: string;
    children: ReactElement;
    onClose: () => void;
    showBackIcon?: boolean;
    onBack?: () => void;
  }
  
  const Container = styled('div')(({theme}) => ({
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    height: "85%",
    bottom: 0,
    left: 0,
    width: "100%",
    zIndex: 100,
    display: 'flex',
    flexDirection: "column",
    borderRadius: "15px 15px 0px 0px"
  }))

const MobilePanel: React.FC<MobilePanelProps> = ({ children, title, isOpen, onClose, onBack = () => {}, showBackIcon = false }) => {
  return (
    <>
    { isOpen &&
        <Container>
            <Stack direction="row" justifyContent="space-between" alignItems="center" paddingX={1}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  { showBackIcon && <IconButton onClick={onBack}><ArrowBack /></IconButton>}
                  <Typography variant='title'>{title}</Typography>
                </div>
                <IconButton onClick={() => onClose()} ><Clear /></IconButton>
            </Stack>
            <Divider />
            {children}
        </Container>
    }
    </>
  )
}

export default MobilePanel