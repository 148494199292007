import { IconProps, styled } from "@mui/material";
import React from "react";
import { MarkerPropBase } from "../common/types";
import { AutomatedRepontIcon, AutomatedRepontIconMohu, AutomatedRepontIconSelected, AutomatedRepontIconSelectedMohu, ManualRepontIcon, ManualRepontIconMohu, ManualRepontIconSelected, ManualRepontIconSelectedMohu } from "../common/Icons";

export interface WastePointMarkerProps extends MarkerPropBase {
    isSelected: boolean;
    icon: string | undefined;
    markerColor?: string;
    markerBgColor?: string;
    selectedColor? : string,
    selectedBgColor? : string,
    isDrsMarker?: boolean,  // DRS page
    isAutomatedRepont?: boolean,
    isRepontMarker?: boolean,   // Repont type point, used on mohu page
}

export const MarkerBg = styled("div")<MarkerProps>(({ theme, isSelected, markerBgColor, selectedBgColor }) => ({
    margin: isSelected ? "-52px 0 0 -52px" : "-32px 0 0 -32px",
    borderRadius: "100px",
    zIndex: 9,
    padding: isSelected ? "15px" : "10px",
    background: isSelected ? (selectedBgColor ??  theme.palette.transparentSelected.main) : (markerBgColor ?? theme.palette.transparentPrimary.main),

    '&:hover': {
        cursor: 'pointer'
    },
}))

type MarkerProps = IconProps & { isSelected: boolean, markerBgColor?: string, selectedBgColor?: string }

const imageSize = 24;
const selectedSize = 32;

const Marker = styled('div')<MarkerProps>(({theme, isSelected, markerBgColor, selectedBgColor}) => ({
    borderRadius: "100px",
    padding: isSelected ? theme.spacing(4) : theme.spacing(1),
    background: isSelected ? (selectedBgColor ?? theme.palette.secondary.main) : (markerBgColor ?? theme.palette.primary.main),
    color: isSelected ? "white" : (markerBgColor ? "white" : "black"),
    fontWeight: `${theme.typography.fontWeightBold}`,
    fontSize: `${theme.typography.caption}`,
    zIndex: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    minWidth: isSelected ? "32px" : "24px",
    minHeight: isSelected ? "32px" : "24px",
}))

const DrsMarker = styled('div')<MarkerProps>(({theme, isSelected, markerBgColor, selectedBgColor}) => ({
    zIndex: 10,

    minWidth: isSelected ? "80px" : "68px",
    marginLeft: isSelected ? "-40px" : "-34px",
    marginTop: isSelected ? "-44.7px" : "-38px",

    '&:hover': {
        cursor: 'pointer'
    },
}))

const WastePointMarker : React.FC<WastePointMarkerProps> = 
    ({ lat, lng, onMouseMove,
       onMouseDown, onMarkerClick,
       isSelected, icon,
       markerColor, markerBgColor,
       selectedColor, selectedBgColor,
       isDrsMarker = false, isAutomatedRepont = false, isRepontMarker = false
     })  => {
        return lat && lng ? 
            isDrsMarker ?   //Blue DRS marker on repont page
                <DrsMarker isSelected={isSelected} markerBgColor={markerBgColor} selectedBgColor={selectedBgColor} onMouseDown={onMouseDown} onMouseMove={onMouseMove} onMouseUp={(event) => { onMarkerClick && onMarkerClick(event) }}>
                    {isAutomatedRepont ?
                        (isSelected ? <AutomatedRepontIconSelected style={{width:"100%", height:"100%"}}></AutomatedRepontIconSelected> : <AutomatedRepontIcon style={{width:"100%", height:"100%"}}></AutomatedRepontIcon> ) :
                        (isSelected ? <ManualRepontIconSelected style={{width:"100%", height:"100%"}}></ManualRepontIconSelected> : <ManualRepontIcon style={{width:"100%", height:"100%"}}></ManualRepontIcon>)}
                </DrsMarker>
                : ( isRepontMarker ? // Yellow DRS marker on mohu page
                    <DrsMarker isSelected={isSelected} markerBgColor={markerBgColor} selectedBgColor={selectedBgColor} onMouseDown={onMouseDown} onMouseMove={onMouseMove} onMouseUp={(event) => { onMarkerClick && onMarkerClick(event) }}>
                        {isAutomatedRepont ?
                            (isSelected ? <AutomatedRepontIconSelectedMohu style={{width:"100%", height:"100%"}}></AutomatedRepontIconSelectedMohu> : <AutomatedRepontIconMohu style={{width:"100%", height:"100%"}}></AutomatedRepontIconMohu> ) :
                            (isSelected ? <ManualRepontIconSelectedMohu style={{width:"100%", height:"100%"}}></ManualRepontIconSelectedMohu> : <ManualRepontIconMohu style={{width:"100%", height:"100%"}}></ManualRepontIconMohu>)}
                    </DrsMarker> :  // Normal mohu marker otherwise
                    <MarkerBg isSelected={isSelected} markerBgColor={markerBgColor} selectedBgColor={selectedBgColor} onMouseDown={onMouseDown} onMouseMove={onMouseMove} onMouseUp={(event) => { onMarkerClick && onMarkerClick(event) }}>
                        <Marker isSelected={isSelected} markerBgColor={markerColor} selectedBgColor={selectedColor}>
                            { icon && <img height={isSelected ? `${selectedSize}px` : `${imageSize}px`} width={isSelected ? "32px" : "24px"} src={`data:image/svg+xml;utf8,${encodeURIComponent(icon)}`} alt="marker ikon"></img> }
                        </Marker> 
                    </MarkerBg>)
            : null
}

export default WastePointMarker;