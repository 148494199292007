import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { MapService, WastePointDetailsDto, WastePointListWithNamesDto } from '../api/app.generated'
import { Alert, Button, Divider, Grid, IconButton, Link, Stack, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { EmailIcon, GlobeIcon, NavArrowIcon } from '../common/Icons';
import { LatLng } from '../common/types';
import { useTranslation } from 'react-i18next';
import { REPONT_CONSTANT } from '../common/constants';

export interface DetailPanelProps {
    wastePointData: WastePointListWithNamesDto;
    onBack: () => void;
    onRoutePlanClick: (coord: LatLng, address: string) => void;
    hideClose?: boolean; 
    isDrsMap?: boolean;
}

const DetailPanel: React.FC<DetailPanelProps> = ({ wastePointData, onBack, onRoutePlanClick, hideClose = false, isDrsMap = false }) => {
    const { t } = useTranslation();
    const [wasteDetails, setWasteDetails] = useState<WastePointDetailsDto | null>(null);

    useEffect(() => {
        const mapService  = new MapService();
         
            if(wasteDetails === null || wasteDetails.id !== wastePointData.id){
                const fetchWastePoint = async () => {
                        const result = await mapService.getWastePointDetails(wastePointData.id);
                        setWasteDetails(result);
                    }
                    fetchWastePoint();
                }
    }, [wastePointData, wasteDetails]);

    const renderWasteTypes = useMemo(() => {
        return Object.entries(wasteDetails?.wasteTypesByCategories ?? {}).map(([categoryName, subCategory], index) => 
            <div key={`${categoryName}-${index}`}>
                    <Typography variant="body1">{categoryName}</Typography>
                    {Object.entries(subCategory).map(([name, items], idx) => 
                    <Fragment key={`${subCategory}-${idx}`}>
                        {name && name !== "" && <Typography variant="body2">{name}</Typography>}
                    </Fragment>)}
                </div>
            );
        }
    , [wasteDetails]);

    return (
        <Stack direction="column" rowGap={1} padding={2}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant='title'>{wasteDetails?.name}</Typography>
                {!hideClose && <IconButton onClick={() => onBack()}><Close /></IconButton> }
            </Stack>
            <Divider />
            <Grid container justifyContent="space-between">
                <Grid item><Typography variant='subtitle1'>{t("DETAILS_PANEL.ADDRESS")}:</Typography></Grid>
                <Grid item>
                    <Typography variant="body1">
                        {`${wasteDetails?.zipCode} ${wasteDetails?.city}, ${wasteDetails?.address}`}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justifyContent="space-between">
               <Grid item><Typography variant='subtitle1'>{t("DETAILS_PANEL.TYPE")}:</Typography></Grid>
                <Grid item><Typography variant="body1">{wasteDetails?.types?.join("; ") ?? ""}</Typography></Grid>
            </Grid>
            { !isDrsMap &&
                <>
                    <Grid container justifyContent="space-between">
                        <Grid item><Typography variant='subtitle1'>{t("DETAILS_PANEL.CONTACT_PHONE")}:</Typography></Grid>
                        <Grid item><Typography variant="body1">{wasteDetails?.normalizedPhoneNumber ?? ""}</Typography></Grid>
                    </Grid>
                    <Grid container justifyContent="space-between">
                        <Grid item><Typography variant='subtitle1'>{t("DETAILS_PANEL.CONTACT_EMAIL")}:</Typography></Grid>
                        <Grid item><Typography variant='body1'>{wasteDetails?.email ?? "" }</Typography></Grid>
                    </Grid>
                    <Grid container justifyContent="space-between">
                        <Grid item><Typography variant='subtitle1'>{t("DETAILS_PANEL.CONTACT_WEBSITE")}:</Typography></Grid>
                        <Grid item><Typography variant='body1'>{wasteDetails?.webSiteUrl ?? ""}</Typography></Grid>
                    </Grid>
                </>
            }
            { wasteDetails?.extraMessages?.some((m) => m) &&<Alert severity='warning'>{wasteDetails?.extraMessages?.map((msg, i) => <p style={{margin: 0}} key={i}>{msg}</p>)}</Alert> }
            <Grid container rowSpacing={1} columnSpacing={1}>
                <Grid item xs={6} md={4}>
                    <Button
                        fullWidth
                        variant='contained'
                        startIcon={<NavArrowIcon />}
                        onClick={() => onRoutePlanClick(
                            new google.maps.LatLng(
                                { lat: wastePointData.coordinates!.latitude, lng: wastePointData.coordinates!.longitude }
                            ),
                            `${wasteDetails?.zipCode} ${wasteDetails?.city} ${wasteDetails?.address}`)}>
                             {t("DETAILS_PANEL.PLAN_ROUTE")}
                    </Button>
                </Grid>
                { !isDrsMap && 
                    <>
                        <Grid item xs={6} md={4}>
                            <Button
                                fullWidth
                                variant='contained'
                                disabled={!wasteDetails?.email}
                                startIcon={<EmailIcon />}
                                component={Link}
                                href={`mailto:\\${wasteDetails?.email}`} 
                                target="_blank">
                                  {t("DETAILS_PANEL.EMAIL_BUTTON")}
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Button
                                fullWidth
                                variant='contained'
                                disabled={!wasteDetails?.webSiteUrl}
                                startIcon={<GlobeIcon />}
                                component={Link}
                                href={`${wasteDetails?.webSiteUrl}`}
                                target="_blank">
                                 {t("DETAILS_PANEL.WEBSITE_BUTTON")}
                            </Button>
                        </Grid>
                    </>
                }
                </Grid>
            <Typography variant='subtitle1'>Leadható hulladék:</Typography>
            {renderWasteTypes}
        </Stack>
    )
}

export default DetailPanel