import React, { useState, useEffect, useMemo, useCallback, useContext } from "react";
import { Coordinate, IWastePointSearchDto, MapService, SearchFilterData, WastePointListDto, WastePointListWithNamesDto, WastePointSearchDto } from "../api/app.generated";
import { DirectionsData, DirectionsRequest, DirectionsResult, DirectionsStatus, FilterValue, LatLng, PredictionWithCoordinate } from "../common/types";
import DirectionsPanel from "../components/DirectionsPanel";
import Map from "../components/Map";
import {  Badge, Button, ButtonProps, Grid, Tab, Tabs, debounce, styled, useMediaQuery, useTheme } from "@mui/material";
import FilterPanel from "../components/FilterPanel";
import TabPanel from "../components/TabPanel";
import SearchPanel from "../components/SearchPanel";
import DetailPanel from "../components/DetailPanel";
import { FilterIcon, RepontIcon, SearchIcon } from "../common/Icons";
import axios, { CancelToken, CanceledError } from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import MobilePanel from "../components/MobilePanel";
import { GoogleMapsApiServiceContext } from "../context/GoogleMapsApiServiceContext";
import { CurrentLocationContext } from "../context/CurrentLocationContext";
import { convertQueryParamsToWastePointSearch, getAutocompletPredictionForAddress, getAutocompletPredictionForCoordinate } from "../common/helpers";
import DirectionInstructions from "../components/DirectionInstructions";
import { REPONT_CONSTANT } from "../common/constants";
import DrsDetailPanel from "../components/DrsDetailPanel";

const MainContainer = styled('div')`
    width: 100%;
    display: flex;
    height: 100%;
`
const MapPanel = styled('div')`
    position: relative;
    width: 80%;
    height: 100%;
`

const LeftPanel = styled('div')(({theme}) =>({
  width: "350px",
  minWidth: "350px",
  maxWidth: "350px",
  display: "flex",
  height: "100vh",
  flexDirection: "column",
  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  backgroundColor: theme.palette.background.paper,
}));


const OverviewLeftPanel = styled('div')(({theme, hidden}) => ({
    backgroundColor: theme.palette.background.paper,
    height: "100vh",
    position: "absolute",
    left: 0,
    top: 0,
    width: "400px",
    minWidth: "400px",
    maxWidth: "400px",
    overflowY: "auto",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    display: hidden? "none" : "flex",
    flexDirection: "column"

}));

const MobileButtonContainer = styled("div")(({ theme }) => ({
  position: 'absolute',
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  right: "0",
  bottom: "15px",
  boxSizing: "border-box"
}));

const LoaderOverViewContainer = styled('div')(() => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "rgba(144, 144, 144, 0.3)",
  zIndex: 1000,
}))

const MobileScrollableContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  overflowY: "auto",
  background: `${theme.palette.background.paper}`,
}))


const FilterButtonContainer = styled("div")(({ theme }) => ({
  ".MuiBadge-root": {
    width: "100%"
  }
}));

const TabContent = styled('div')(() => ({
  height: "100%",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column"
}));

const SearchTabContent = styled('div')(({ theme }) => ({
  height: "100%",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  background: `${theme.palette.background.paper}`,
  padding: `${theme.spacing(2)}`,
  paddingBottom: theme.spacing(1),
}));

const defaultDirectionsData: DirectionsData = {
  origin: null,
  destination: null,
  route: null,
  waypoints: [],
  routeAvoidOptions: {
    ferries: false,
    tolls: false,
    highways: false
  }
}

enum TabMenuPoint {
  SearchTab = 0,
  DirectionTab = 1
}

const MapPage: React.FC = () => {
  const { mapsApi } = useContext(GoogleMapsApiServiceContext);
  const { location } = useContext(CurrentLocationContext);

  const searchParams = new URLSearchParams(document.location.search)
  const [allWastePoints, setAllWastePoints] = useState<WastePointListDto[]>([]);
  const [filteredWastePointList, setFilteredWastePointList] = useState<WastePointListWithNamesDto[] | undefined>(undefined);

  const [wastePointSearch, setWastePointSearch] = useState<WastePointSearchDto>(new WastePointSearchDto({ wastePointTypes: [...searchParams.getAll("type")], hideDrsPoints: false }));
  const [selectedWastePoint, setSelectedWastePoint] = useState<WastePointListWithNamesDto | null | undefined>();
  const [searchFilterOptions, setSearchFilterOptions] = useState<SearchFilterData | null>(null);
  const [directionsData, setDirectionsData] = useState<DirectionsData>(defaultDirectionsData);
  const [searchLocation, setSearchLocation] = useState<PredictionWithCoordinate | undefined>();
 
  const [currentTab, setCurrentTab] = useState(0);
  const [toggleFilter, setToggleFilter] = useState(false);
  const [toggleDetails, setToggleDetails] = useState(false);
  const [toggleOnlyRepont, setToggleOnlyRepont] = useState(false);

  const [searchCancelToken, setSearchCancelToken] = useState(axios.CancelToken.source());
  const [defaultDestination, setDefaultDestination] = useState<string | undefined>();

  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false);
  const [isAllPointLoading, setIsAllPointLoading] = useState<boolean>(false);
  const [isRoutesLoading, setIsRoutesLoading] = useState<boolean>(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isMobileTabsOpen, setIsMobileTabsOpen] = useState(false);
  const [mobileDirectionsStep, setMobileDirectionsStep] = useState<1 | 2>(1);

  const isDrs = useMemo(() => selectedWastePoint?.types?.some(t => t.toLowerCase() === REPONT_CONSTANT), [selectedWastePoint]);

  const isFilterActive = useCallback((currentSearch: IWastePointSearchDto) => {
     return (((currentSearch.radiusKm ?? 0) > 0) && (!!currentSearch.coordinates || !!currentSearch.routePolyLine)) ||
              (currentSearch.wasteCategories?.length ?? 0) > 0 
              ||
              ((currentSearch.wastePointTypes?.length ?? 0) > 0 &&
              // If categories have only REpont filtering it does not count as active filter
              !(currentSearch.wastePointTypes?.length === 1 && currentSearch.wastePointTypes[0].toLowerCase() === REPONT_CONSTANT))
   }, []);

  const fetchWastePoints = useMemo(() => {
         const mapService = new MapService();
         return debounce(async (wastePointSearch: WastePointSearchDto, cancelToken: CancelToken) => {
           try {
             setIsSearchLoading(true);
             const result = await mapService.searchWastePoints(wastePointSearch, cancelToken);
             setIsSearchLoading(false);
             setFilteredWastePointList(result ?? []);
           } catch (e){
             setIsSearchLoading(false);
             if(e instanceof CanceledError){
               return;
             }
             throw e;
           };
         },
         400);
  }, []);
   
  const fetchAllWastePoints = useMemo(() => {
      const mapService = new MapService(); 
      return debounce(async () => {
        setIsAllPointLoading(true);
        const result = await mapService.getAllWastePoints(false);
        setAllWastePoints(result ?? []);
        setIsAllPointLoading(false);
      },
      400);
   }, []);
       
   useEffect(() => {
     const mapService = new MapService();
      const fetch = async () => {
       const result = await mapService.getSearchFilterData();
       setSearchFilterOptions(result);
     }
     fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])
  
   
   useEffect(() => {
    var search = convertQueryParamsToWastePointSearch(searchParams);
    if(search){
      setWastePointSearch(search);
      if(mapsApi){
        fetchWastePoints(search, searchCancelToken.token);
      }
      if(search.wastePointTypes?.length === 1 && search.wastePointTypes[0].toLowerCase() === REPONT_CONSTANT){
        setToggleOnlyRepont(true);
      }
    } 
  
    fetchAllWastePoints();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapsApi])

   useEffect(() => {
     if(currentTab === 0){
       resetDirection();
     } else {
       handleSearch({...wastePointSearch, location: undefined });
     }
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab])

  useEffect(() => {
     if(location && !wastePointSearch.coordinates){
       const wasteSearch = new WastePointSearchDto({ ...wastePointSearch, coordinates: new Coordinate({ latitude: location.lat(), longitude: location.lng() }) });
       handleSearch(wasteSearch);
     }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [location]);

  const planRoute = async (data: DirectionsData) => {
     setIsRoutesLoading(true);
     const request: DirectionsRequest = {
         origin: data.origin instanceof google.maps.LatLng ? data.origin : (data.origin?.description ?? ""),
         destination: data.destination instanceof google.maps.LatLng ? data.destination : data.destination?.description ?? "",
         travelMode: google.maps.TravelMode.DRIVING,
         waypoints: data.waypoints.filter(item => item !== null).map(item =>  ({ location: item?.description, stopover: true })),
         avoidHighways:  data.routeAvoidOptions.highways,
         avoidFerries:  data.routeAvoidOptions.ferries,
         avoidTolls:  data.routeAvoidOptions.tolls
     }
       await mapsApi?.directionsService?.route(request, 
         (result, status) => { displayDirection(result, status); setDirectionsData({ ...data, route: result }) });
         setIsRoutesLoading(false);
     if(isMobile)
       setMobileDirectionsStep(2);
  }

  const displayDirection = async (result: DirectionsResult | null, status: DirectionsStatus) => {
     if (status === 'OK' && mapsApi?.directionsRenderer) {
       mapsApi.directionsRenderer.setDirections(result);
       const searchDto = new WastePointSearchDto({ ...wastePointSearch, routePolyLine: result?.routes[0].overview_polyline ?? "" });
       await handleSearch(searchDto);
     }
   }
  
      const resetDirection = async () => {
        if(mapsApi?.directionsRenderer){
          mapsApi.directionsRenderer.set('directions', null);
          const searchDto = new WastePointSearchDto({ ...wastePointSearch, routePolyLine: undefined });
          await handleSearch(searchDto);
        }
        if(defaultDestination){
          setDefaultDestination(undefined);
        }
        setDirectionsData(defaultDirectionsData);
      }

      const handleWastePointClick = (itemId: number, isDrs: boolean, event?: React.MouseEvent) => {
        const item = allWastePoints.find(
         item => item.id === itemId && 
                (isDrs ? item.types?.some(t => t.toLowerCase() === REPONT_CONSTANT) : item.types?.every(t => t.toLowerCase() !== REPONT_CONSTANT))
        );
        if(item){
          setSelectedWastePoint(item);
          setToggleFilter(false);
          setToggleDetails(true);
          if(item.coordinates && mapsApi?.mapRef) {
            mapsApi.mapRef.panTo(new google.maps.LatLng({ lat: item.coordinates.latitude, lng: item.coordinates.longitude }));
            mapsApi.mapRef.setZoom(Math.max(mapsApi.mapRef.zoom, 14));
          }
        }
        if(event){
          event.stopPropagation();
        }
      }

      const zoomWithRadius = (searchDto: WastePointSearchDto) => {
        if(searchDto.coordinates && !searchDto.routePolyLine){
          const circle = new google.maps.Circle({
            center: new google.maps.LatLng({ lat: searchDto.coordinates!.latitude, lng: searchDto.coordinates!.longitude }),
            radius: ((searchDto.radiusKm ?? 0) > 0 ? searchDto.radiusKm! : 10) * 1000, // radius in meter
          });
          mapsApi!.mapRef.fitBounds(circle.getBounds());
        } else {
          zoomDefault()
        }
      }

      const zoomDefault = () => {
        const circle = new google.maps.Circle({
          center: new google.maps.LatLng({ lat: location ? location.lat() : 47.168462, lng: location ? location.lng() : 19.395633 }),
          radius: location ? 10000 : 100000, // 100 000 méteres körzetbe mutassuk a pineket a szűrő törlésekor
        });
        mapsApi!.mapRef.fitBounds(circle.getBounds());
      }

      const handleSearch = async (item: IWastePointSearchDto) => {
        const searchDto = new WastePointSearchDto({...wastePointSearch, ...item });
        setWastePointSearch(searchDto);
        if(isFilterActive(searchDto) || (searchDto.wastePointTypes?.length ?? 0) > 0 || searchDto.location)
        {
          await fetchWastePoints(searchDto, searchCancelToken.token);
          zoomWithRadius(searchDto)
        } else {
          // Clear search results and cancel current search request
          searchCancelToken.cancel();
          setSearchCancelToken(axios.CancelToken.source())
          setFilteredWastePointList(undefined);
          if(allWastePoints.length <=0){
            fetchAllWastePoints();
          }
          if(!searchDto.coordinates){
            zoomDefault()
          }
        }
      }

      const handleFilter = (filterValue: FilterValue) => {
        handleSearch(filterValueToSearchDto(filterValue));
        if(isMobile){
          // close all other panels and repoen the tabbed panel
          closeMobilePanel();
        }
      }

      const clearFilter = () => {
        handleFilter({ radiusKm: undefined, wasteCategories: [], wastePointTypes: [] })
      }

      const filterValueToSearchDto = (filterValue: FilterValue) => {
        return new WastePointSearchDto({
          ...wastePointSearch,
          radiusKm: filterValue.radiusKm,
          wastePointTypes: filterValue.wastePointTypes,
          wasteCategories: filterValue.wasteCategories.flatMap(item => item.selectedSubCategories.length > 0 ? item.selectedSubCategories.map(sc => sc.name) : item.name),
        });
      }

      const handleRoutePlan = async (destCoord: LatLng, destAddress: string) => {
        resetDirection();
        if(currentTab !== TabMenuPoint.DirectionTab){
          setCurrentTab(TabMenuPoint.DirectionTab);
        }
        

        let destPrediction = await getAutocompletPredictionForAddress(destAddress);
        if(!destPrediction){
          destPrediction = await getAutocompletPredictionForCoordinate(destCoord);
        }
        let locationPrediction;

        if(location){
          locationPrediction = await getAutocompletPredictionForCoordinate(location);
        }

        setDirectionsData({...directionsData, origin: locationPrediction ?? null,  destination: destPrediction ?? null });
        if(location && destCoord){
          planRoute({...directionsData, origin: locationPrediction ?? location,  destination: destPrediction ?? null });
        }

        if(isMobile){
          // close all other panels and repoen the tabbed panel
          closeMobilePanel();
          setIsMobileTabsOpen(true);
        }
      }
    
      const handleFilterButtonClick = () => {
        if(isMobile){
          setIsMobileTabsOpen(true)
        }; 
        setToggleFilter(true);
        setToggleDetails(false);
      }
  
  const handleRouteInstructionsBack = () => {
    setMobileDirectionsStep(1);
  }

  const handleSearchValueChange = (item: PredictionWithCoordinate | undefined) => {
    setSearchLocation(item);
    if(item){
      handleSearch({ coordinates: new Coordinate({ latitude:  item.coordinate.lat(), longitude: item.coordinate.lng()}), radiusKm: wastePointSearch.radiusKm ?? 0 });
      if(!wastePointSearch.radiusKm || wastePointSearch.radiusKm === 0){
        const circle = new google.maps.Circle({
          center: new google.maps.LatLng({ lat: item.coordinate.lat(), lng: item.coordinate.lng() }),
          radius: 10 * 1000, // radius in meter
        });
        mapsApi!.mapRef.fitBounds(circle.getBounds());
      }
    } else {
      handleSearch({ coordinates: location ? new Coordinate({ latitude:  location.lat(), longitude: location.lng()}) : undefined  });
    }
    
  }

  const closeMobilePanel = () => {
    setIsMobileTabsOpen(false); 
    setToggleDetails(false); 
    setToggleFilter(false);
  }

  const FilterButton: React.FC<ButtonProps> = ({ ...props }) => (
    <>
      {toggleFilter ?
        <Button color="primary" fullWidth variant="outlined" startIcon={<FilterIcon />} onClick={() => { setToggleFilter(false)}} {...props}>
          Szűrő bezárása
        </Button>
        :
        <Button color="primary" fullWidth variant="contained" startIcon={<FilterIcon />} onClick={handleFilterButtonClick} {...props}>
          {isMobile ? "Szűrő" : "Részletes szűrő"}
        </Button>
      }   
    </>
  );
  
  const RepontButton: React.FC<ButtonProps> = ({ ...props }) => (
    <>
      {toggleOnlyRepont ?
        <Badge color="error" badgeContent="" >
          <Button color="primary" sx={{ backgroundColor: "white" }} fullWidth variant="outlined" startIcon={<RepontIcon />} onClick={() => { setToggleOnlyRepont(false); clearFilter()}} {...props}>
            {isMobile ? "REpontok ki" : "REpontok kikapcsolása"}
          </Button>
        </Badge>
        :
        <Button color="primary" fullWidth variant="contained" startIcon={<RepontIcon />} onClick={() => { setToggleOnlyRepont(true); setToggleFilter(false); handleSearch({ wastePointTypes: [REPONT_CONSTANT], wasteCategories: [],  radiusKm: 0 }) }} {...props}>
           {isMobile ? "REpontok" : "Mutasd a REpontokat"}
        </Button>
      }   
    </>
  );

  const FilterButtonWithBadge: React.FC<ButtonProps> = (props) => (
    <FilterButtonContainer>
      {isFilterActive(wastePointSearch) ? 
        <Badge hidden={isFilterActive(wastePointSearch) ?? true} color="error" badgeContent="" >
          <FilterButton {...props} />
        </Badge>
        : 
        <FilterButton {...props}/>
      }
    </FilterButtonContainer>
  )
 
  const renderTabs = (
    <>
      <Tabs  value={currentTab} onChange={(event, value: number) => setCurrentTab(value)} >
        <Tab style={{ width: "50%" }} label="Térképes kereső" />
        <Tab style={{ width: "50%" }} label="Útvonalterv" />
      </Tabs>
      <TabPanel value={currentTab} index={TabMenuPoint.DirectionTab}>
        <TabContent>
          <div style={{ padding: theme.spacing(2) }}>
            <FilterButtonWithBadge disabled={toggleOnlyRepont} />
          </div>
          {mapsApi?.mapsLibraries ? 
            <DirectionsPanel
              isRouteLoading={isRoutesLoading}
              onPlanRouteClick={() => planRoute(directionsData)}
              resetDirection={resetDirection}
              directionsData={directionsData}
              setDirectionsData={setDirectionsData}
              isDrsPage={false}
            /> : null}
        </TabContent>
      </TabPanel>
      <TabPanel value={currentTab} index={TabMenuPoint.SearchTab}>
        <SearchTabContent>
          <FilterButtonWithBadge sx={{ marginBottom: "10px"}} disabled={toggleOnlyRepont} />
          <RepontButton />
          <SearchPanel
            selectedWastePointId={selectedWastePoint?.id}
            wastePointList={filteredWastePointList ?? []}
            onWastePointClick={handleWastePointClick}
            searchValue={searchLocation}
            onSearch={handleSearchValueChange}
            isSearchLoading={isSearchLoading}
            isDrsPage={false}
            isDrsToggled={toggleOnlyRepont}
          />
        </SearchTabContent>
      </TabPanel>
    </>
  );


  const renderMobileOverviewButtons = (
    <MobileButtonContainer>
      <Grid container spacing={1} padding={1}>
        <Grid item xs={4}>
          <Button fullWidth color="primary" startIcon={<SearchIcon />} variant="contained" onClick={() => { setIsMobileTabsOpen(true) }} >
            Kereső
          </Button>
        </Grid>

        <Grid item xs={3}>
          {isFilterActive(wastePointSearch) ? 
            <Badge sx={{ width: "100%" }} hidden={isFilterActive(wastePointSearch) ?? true} color="error" badgeContent="" >
              <FilterButton fullWidth disabled={toggleOnlyRepont} />
            </Badge>
            : 
            <FilterButton fullWidth disabled={toggleOnlyRepont} />
          }
        </Grid>
        
        <Grid item xs={5}>
          <RepontButton />
        </Grid>
      </Grid>
    </MobileButtonContainer>
  )

  const renderMobilePanels = () => {
    if(toggleFilter && !toggleDetails){
      return <MobilePanel isOpen={toggleFilter && !toggleDetails} title="" onClose={closeMobilePanel}>
              <FilterPanel
                currentSearch={wastePointSearch}
                onFilter={handleFilter}
                onClearFilter={clearFilter}
                onClose={() => { setToggleFilter(false);setToggleDetails(false);}}
                searchFilterOptions={searchFilterOptions}
                hasRoute={directionsData.route !== null}
                searchLocation={searchLocation?.coordinate}
                />
            </MobilePanel>
    }
    if(toggleDetails && selectedWastePoint){
      return <MobilePanel isOpen={toggleDetails && !!selectedWastePoint} title="Találati eredmény" onClose={closeMobilePanel}>
        <MobileScrollableContainer>
          { isDrs ? 
            <DrsDetailPanel wastePointData={selectedWastePoint} onBack={() => { setToggleDetails(false); setToggleFilter(false); setSelectedWastePoint(undefined);}} onRoutePlanClick={handleRoutePlan} hideClose />
            :
            <DetailPanel wastePointData={selectedWastePoint} onBack={() => { setToggleDetails(false); setToggleFilter(false); setSelectedWastePoint(undefined);}} onRoutePlanClick={handleRoutePlan} hideClose />
          }
          </MobileScrollableContainer>
      </MobilePanel>
    }

    if(isMobileTabsOpen){
      return mobileDirectionsStep === 1 ?
       (<MobilePanel isOpen={isMobileTabsOpen} title="Keresés" onClose={closeMobilePanel}>
          {renderTabs}
        </MobilePanel>) :
      (<MobilePanel showBackIcon isOpen={isMobileTabsOpen} title="Útvonalterv" onBack={handleRouteInstructionsBack} onClose={handleRouteInstructionsBack}>
        <MobileScrollableContainer sx={{ padding: theme.spacing(1) }} >
          <DirectionInstructions route={directionsData.route} />
          <Button variant="outlined" onClick={handleRouteInstructionsBack}>Vissza</Button>
        </MobileScrollableContainer>
      </MobilePanel>)
    }
  }


  return  (
      <MainContainer>
        {isMobile ? 
          renderMobilePanels()
          :
          <LeftPanel>
            {renderTabs}
          </LeftPanel>
        }
        <MapPanel style={{ width: '100%' }}>
          <Map
            wastePointList={filteredWastePointList ?? allWastePoints}
            selectedWastePointId={selectedWastePoint?.id}
            onMarkerClick={handleWastePointClick}
            wasteTypeList={searchFilterOptions?.wastePointTypes}
            onMapClick={() => { 
              setSelectedWastePoint(undefined);
              setToggleDetails(false);}}
            searchLocation={wastePointSearch.coordinates}
            />
            {(isAllPointLoading || isSearchLoading) && 
            <LoaderOverViewContainer>
              <CircularProgress disableShrink={true} size={120} />
            </LoaderOverViewContainer> }

          {isMobile && renderMobileOverviewButtons}
          
          {!isMobile &&
            <OverviewLeftPanel hidden={!toggleFilter && !toggleDetails}>
                {toggleFilter && !toggleDetails &&
                  <FilterPanel
                    currentSearch={wastePointSearch}
                    onFilter={handleFilter}
                    onClearFilter={clearFilter}
                    onClose={() => { setToggleFilter(false);setToggleDetails(false);}}
                    searchFilterOptions={searchFilterOptions}
                    hasRoute={directionsData.route !== null}
                    searchLocation={searchLocation?.coordinate}
                  />
                }
                {toggleDetails && selectedWastePoint &&
                <>
                    { isDrs ? 
                      <DrsDetailPanel wastePointData={selectedWastePoint} onBack={() => { setToggleDetails(false); setToggleFilter(false); setSelectedWastePoint(undefined);}} onRoutePlanClick={handleRoutePlan} hideClose />
                      :
                      <DetailPanel wastePointData={selectedWastePoint} onBack={() => { setToggleDetails(false); setToggleFilter(false); setSelectedWastePoint(undefined);}} onRoutePlanClick={handleRoutePlan} hideClose />
                    }
                </>
                }
            </OverviewLeftPanel>
          }        
        </MapPanel>
    </MainContainer>);
};

export default MapPage;