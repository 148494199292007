import React, { ReactNode, useState } from 'react'
import { GoogleMapsApiServiceContext } from '../context/GoogleMapsApiServiceContext';
import { MapsApiServices, MapsLibraries } from '../common/types';

interface GoogleMapsApiServiceProviderProps {
    children: ReactNode;
}

const GoogleMapsApiServiceProvider: React.FC<GoogleMapsApiServiceProviderProps> = ({ children }) => {
  const [mapsApiServices, setMapsApiServices] = useState<MapsApiServices | undefined>();
  
  const setApi = (mapsLibraries: MapsLibraries, mapRef: any) => {
    const dirRenderer = new mapsLibraries.DirectionsRenderer();
    dirRenderer.setMap(mapRef);
    setMapsApiServices({
      mapsLibraries,
      mapRef,
      directionsService: new mapsLibraries.DirectionsService(),
      directionsRenderer: dirRenderer,
      geocoder: new google.maps.Geocoder(),
    })
  }

  return (
    <GoogleMapsApiServiceContext.Provider value={{ mapsApi: mapsApiServices, setApi }}>
        {children}
    </GoogleMapsApiServiceContext.Provider>
  )
}

export default GoogleMapsApiServiceProvider