import React from 'react'
import { DirectionsResult } from '../common/types'
import { Grid, Stack, Typography } from '@mui/material';
import { DirectionsBoat, ForkLeft, ForkRight, Merge, RampLeft, RampRight, RoundaboutLeft, RoundaboutRight, Straight, Train, TurnLeft, TurnRight, TurnSharpLeft, TurnSharpRight, TurnSlightLeft, TurnSlightRight, UTurnLeft, UTurnRight } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export interface DirectionInstructionsProps {
    route: DirectionsResult | null;
};

const ManeuverToIcon = new Map<string, JSX.Element | null>(Object.entries({
    "TURN_SLIGHT_LEFT": <TurnSlightLeft />,
    "TURN_SHARP_LEFT": <TurnSharpLeft />,
    "UTURN_LEFT": <UTurnLeft />,
    "TURN_LEFT": <TurnLeft />,
    "TURN_SLIGHT_RIGHT": <TurnSlightRight />,
    "TURN_SHARP_RIGHT": <TurnSharpRight />,
    "UTURN_RIGHT": <UTurnRight />,
    "TURN_RIGHT": <TurnRight />,
    "STRAIGHT": <Straight />,
    "RAMP_LEFT": <RampLeft />,
    "RAMP_RIGHT": <RampRight />,
    "MERGE": <Merge />,
    "FORK_LEFT": <ForkLeft />,
    "FORK_RIGHT": <ForkRight />,
    "FERRY": <DirectionsBoat />,
    "FERRY_TRAIN": <Train />,
    "ROUNDABOUT_LEFT": <RoundaboutLeft />,
    "ROUNDABOUT_RIGHT": <RoundaboutRight />
}));


const DirectionInstructions: React.FC<DirectionInstructionsProps> = ({ route }) => {
    const { t } = useTranslation();

  if(route && route.routes[0]){
    const legs = route.routes[0].legs;
    return (
        <Stack direction="column">
            <Typography variant='subtitle1'>
                {t("DIRECTIONS_PANEL.INSTRUCTIONS.PLAN")}
            </Typography>
            {route &&
                    legs.map((leg, index) => 
                        <Stack key={`leg-${index}`} direction="column">
                            { legs.length > 1 && <Typography variant="body1">{t("DIRECTIONS_PANEL.INSTRUCTIONS.LEGS",{ num: index +1})}</Typography> }

                            <Typography variant="body1">{leg.start_address}</Typography>
                            <Typography variant="body1">{leg.distance?.text} {leg.duration?.text}</Typography>
                            {
                                leg.steps.map((step, index) =>
                                 <Grid container key={`step-${index}`} spacing={1} >
                                    <Grid item xs={1}>
                                        {ManeuverToIcon.get(step.maneuver.replace("-", "_").toUpperCase())}
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography  variant='subtitle1'>{index+1} </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography  variant='subtitle1' dangerouslySetInnerHTML={{__html: step.instructions }}></Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography  variant='subtitle1'>{step.distance?.text}</Typography>
                                    </Grid>
                                 </Grid>
                                )
                            }
                             <Typography variant="body1">{t("DIRECTIONS_PANEL.INSTRUCTIONS.GOAL")}: {leg.end_address}</Typography>
                        </Stack>

                    )}
        </Stack>
    )}
  return null;
}

export default DirectionInstructions;