import { Chip, ChipProps, styled } from "@mui/material";
import React from "react";
import { MarkerBg } from "./WastePointMarker";
import { MarkerPropBase } from "../common/types";

export interface ClusterMarkerProps extends MarkerPropBase {
    count: number,
    markerColor?: string;
    markerBgColor?: string;
}

type MarkerProps = ChipProps & { markerColor?: string };


const Marker = styled(Chip, { shouldForwardProp: (prop) => prop !== "markerColor"})<MarkerProps>(({theme, markerColor }) => ({
    background: markerColor ?? theme.palette.primary.main,
    fontWeight: `${theme.typography.fontWeightBold}`,
    fontSize: `${theme.typography.caption}`,
    color: markerColor ? "white" : "inherit"
}))

const ClusterMarker : React.FC<ClusterMarkerProps> = 
    ({ lat, lng, count, onMarkerClick, onMouseDown, onMouseMove, markerColor, markerBgColor })  => {
        return lat && lng ?
            <MarkerBg isSelected={false} onMouseUp={onMarkerClick} markerBgColor={markerBgColor} onMouseDown={onMouseDown} onMouseMove={onMouseMove}>
                <Marker markerColor={markerColor} label={count}></Marker> 
            </MarkerBg>
            : null
}

export default ClusterMarker;