import React from "react";

interface TabPanelProps {
    children?: React.ReactNode;
    style?: React.CSSProperties;
    index: number;
    value: number;
    innerElementProps?: React.HTMLProps<HTMLDivElement>
  }
  
  const TabPanel : React.FC<TabPanelProps> = (props: TabPanelProps) =>  {
    const { children, value, index, style, innerElementProps } = props;
  
    return (
      <div
        style={{ overflow: "hidden", ...style }}
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...innerElementProps}
      >
        {value === index && (
            <>{children}</>
        )}
      </div>
    );
  }

export default TabPanel;