import styled from "@emotion/styled";
import { Place } from "@mui/icons-material";
import { SvgIconProps } from "@mui/material";

type SearchLocationMarkerProps = {
    iconProps: SvgIconProps;
    lat: number,
    lng: number,
    zIndex: number }

const Icon = styled(Place)<SvgIconProps>(() => ({
    marginLeft: "-32px",
    marginTop: "-64px",
    fontSize: "48px",
    pointerEvents: "none"
  }));
  
const SearchLocationMarker: React.FC<SearchLocationMarkerProps> = ({ iconProps, ...props }) => {
    return <Icon {...iconProps} />
}

export default SearchLocationMarker;