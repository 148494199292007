import { createTheme } from "@mui/material";

export const defaultTheme = createTheme({
    palette: {
      primary: {
        main: "#D6DF23",
      },
      secondary: {
        main: "#919948"
      },
      transparentPrimary: { 
        main: "#D6DF2333"
      },
      transparentSelected: {
        main: "#91994833"
      },
      drsMarker: {
        main: "#00B5DB",
        dark: "#4ED2EE"
      },
      transparentDrsMarker: {
        main: "#00B5DB33",
        dark: "#4ED2EE33"
      },
    },
    typography: {
      fontFamily: [
        '"Ubuntu"',
        'sans-serif'
      ].join(','),
      body1: {
        fontSize: '14px',
        fontWeight: 600,
      },
      body2: {
        fontSize: '13px',
        fontWeight: 400,
      },
      subtitle1: {
        fontSize: '13px',
        fontWeight: 400,
        color: "#A0A0A0"
      },
      title: {
        fontSize: '16px',
        fontWeight: 600,
      }
    },
    spacing: [0, 10, 14, 16, 20, 24],
    components: {
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState }) => ({           
            fontSize: "14px",
            textTransform: "none",
            borderRadius: "12px",
            ...(ownerState.variant === 'contained' &&
              ownerState.color === 'primary' && {
                backgroundColor: "#D9E100",
                color: 'black',
                boxShadow: "none",
              }),
              ...(ownerState.variant === 'outlined' &&
              ownerState.color === 'primary' && {
                color: 'black',
              }),
            }),
         
          }
      },
      MuiTab: {
        styleOverrides: {
          root: () => ({
            fontSize: "14px",
            textTransform: "none",
          }),
        }  
      }
    }
  });

  export const drsTheme = createTheme({
    palette: {
      primary: {
        main: "#00B5DB",
      },
      secondary: {
        main: "#4ED2EE"
      },
      transparentPrimary: { 
        main: "#D6DF2333"
      },
      transparentSelected: {
        main: "#91994833"
      },
      drsMarker: {
        main: "#00B5DB",
        dark: "#4ED2EE"
      },
      transparentDrsMarker: {
        main: "#00B5DB33",
        dark: "#4ED2EE33"
      },
    },
    typography: {
      fontFamily: [
        '"Ubuntu"',
        'sans-serif'
      ].join(','),
      body1: {
        fontSize: '14px',
        fontWeight: 600,
      },
      body2: {
        fontSize: '13px',
        fontWeight: 400,
      },
      subtitle1: {
        fontSize: '13px',
        fontWeight: 400,
        color: "#A0A0A0"
      },
      title: {
        fontSize: '16px',
        fontWeight: 600,
      }
    },
    spacing: [0, 10, 14, 16, 20, 24],
    components: {
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState }) => ({           
            fontSize: "14px",
            textTransform: "none",
            borderRadius: "12px",
            ...(ownerState.variant === 'contained' &&
              ownerState.color === 'primary' && {
                backgroundColor: "#00B5DB",
                color: 'white',
                boxShadow: "none",
              }),
              ...(ownerState.variant === 'outlined' &&
              ownerState.color === 'primary' && {
                color: 'black',
              }),
            }),
         
          }
      },
      MuiTab: {
        styleOverrides: {
          root: () => ({
            fontSize: "14px",
            textTransform: "none",
          }),
        }  
      }
    }
  });

  declare module '@mui/material/styles' {
    interface TypographyVariants {
      title: React.CSSProperties;
    }
  
    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
      title?: React.CSSProperties;
    }
  }
  
  // Update the Typography's variant prop options
  declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
      title: true;
    }
  }

  declare module '@mui/material/styles' {
    interface Palette {
      transparentSelected: Palette['primary'];
      transparentPrimary: Palette['primary'];
      drsMarker: Palette['primary'];
      transparentDrsMarker: Palette['primary'];
    }
  
    interface PaletteOptions {
      transparentSelected?: PaletteOptions['primary'];
      transparentPrimary?: PaletteOptions['primary'];
      drsMarker: PaletteOptions['primary'];
      transparentDrsMarker: PaletteOptions['primary'];
    }
  }